<template>
  <section class="all-documents-view">
    <el-row
      :type="getIsMobile ? '' : 'flex'"
      justify="center"
      align="center"
      :style="
        getIsMobile
          ? 'display:block'
          : 'display: flex; justify-content: space-between;'
      "
    >
      <el-col v-if="getIsMobile" :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
        <h3
          class="title fw-normal text-dark mb-0 ls-05"
          :style="getIsMobile ? 'margin-top:10px;margin-left:2px' : ''"
        >
          Documents
        </h3>
      </el-col>
      <el-col v-if="!getIsMobile" :xl="8" :lg="16" :md="6" :sm="12" :xs="12">
        <el-col
          :xl="6"
          :lg="10"
          :md="12"
          :sm="12"
          :xs="12"
          style="margin-top: 10px"
        >
          <h3
            class="fw-medium blue-dark mb-3 fs-6"
            :style="
              this.$route &&
              this.$route.query &&
              this.$route.query.type &&
              this.$route.query.type == 'company-documents' &&
              checkPermissionByPermissionName('viewDocument') &&
              !getIsMobile
                ? ''
                : 'margin-left:0px'
            "
          >
            Documents
          </h3>
        </el-col>
        <el-col :xl="18" :lg="18" :md="12" :sm="24">
          <el-dropdown
            class="import-dropdown-btn"
            v-if="this.selected_document.length"
          >
            <el-button size="mini" class="el-dropdown-link px-2">
              Delete
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <a
                v-if="
                  this.$route.query.type && this.$route.query.type == 'deleted'
                "
                @click="checkCondition"
              >
                <el-dropdown-item>
                  <i class="el-icon-delete-solid"></i>
                  <span>Permanent Delete</span>
                </el-dropdown-item>
              </a>
              <a v-else @click="checkCondition">
                <el-dropdown-item>
                  <i class="el-icon-delete"></i>
                  <span>Delete</span>
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-col>
      <el-col
        v-if="selectedFilter == 'custom_date'"
        :class="getIsMobile ? 'ml-0 mb-2' : 'ml-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
      >
        <el-date-picker
          placeholder="Pick a day"
          type="date"
          v-model="selectedDate"
        ></el-date-picker>
      </el-col>
      <el-col
        v-if="selectedFilter == 'custom_date_range'"
        :class="getIsMobile ? 'ml-0 mb-2' : 'ml-2 mb-2'"
        :xl="8"
        :lg="8"
        :md="8"
        :sm="12"
        :xs="12"
      >
        <el-date-picker
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          v-model="selectedDateRange"
        ></el-date-picker>
      </el-col>
      <el-col
        :class="getIsMobile ? 'ml-0 mb-2' : 'ml-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
      >
        <el-select
          v-model="selectedFilter"
          filterable
          clearable
          class="select"
          placeholder="Filter by Time Period"
          :style="
            getIsMobile ? 'width:130px;margin-bottom:10px' : 'width:180px;'
          "
        >
          <el-option
            v-for="(status, index) in filterByOption"
            :key="index"
            :value="status.value"
            :label="status.name"
          ></el-option>
        </el-select>
      </el-col>
     
      <el-col
        v-if="
          this.$route &&
          this.$route.query &&
          this.$route.query.type &&
          this.$route.query.type == 'company-documents' &&
          checkPermissionByPermissionName('viewDocument') &&
          !getIsMobile
        "
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="24"
      >
        <el-select
          v-model="selected_company_user"
          size="medium"
          filterable
          clearable
          class="select float-right"
          placeholder="Select Company User"
        >
          <el-option
            v-for="(status, index) in companyUsers"
            :key="index"
            :value="status.first_name + ' ' + status.last_name"
            :label="status.first_name + ' ' + status.last_name"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col
        v-if="
          this.getActiveWorkspace &&
          this.isIndividualWorkspace(this.getActiveWorkspace)
        "
        :class="getIsMobile ? '' : 'mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        style="text-align: right"
      >
        <el-select
          v-model="selectedFilter"
          filterable
          clearable
          class="select"
          placeholder="Filter by Time Period"
        >
          <el-option
            v-for="(status, index) in filterByOption"
            :key="index"
            :value="status.value"
            :label="status.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col
        v-if="
          this.getActiveWorkspace &&
          this.isIndividualWorkspace(this.getActiveWorkspace)
        "
        :class="getIsMobile ? '' : 'mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        style="text-align: right"
      >
        <el-select
          v-model="selectedStatus"
          filterable
          clearable
          class="select"
          placeholder="Select Status"
        >
          <el-option
            v-for="(status, index) in statusOptionIndividual"
            :key="index"
            :value="status.value"
            :label="status.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col
        v-else
        :class="getIsMobile ? 'ml-0 mb-2' : 'ml-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
      >
        <el-select
          v-model="selectedStatus"
          filterable
          clearable
          class="select"
          placeholder="Select Status"
          :style="
            getIsMobile ? 'width:130px;margin-bottom:10px' : 'width:170px;'
          "
        >
          <el-option
            v-for="(status, index) in statusOption"
            :key="index"
            :value="status.value"
            :label="status.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col
        :class="getIsMobile ? '' : 'mr-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        v-if="getIsMobile"
      >
        <el-input
          class="search px-0"
          placeholder="Search"
          v-model="search_string"
          clearable
          :style="getIsMobile ? 'width:130px;' : ''"
        >
        </el-input>
      </el-col>
      <el-col
        v-else
        :class="getIsMobile ? '' : 'ml-2 mb-2'"
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        style="text-align: right"
      >
        <el-tooltip placement="top" :hide-after="2000">
          <div slot="content">
            You can search by first name, last name,email, and<br />address that
            are present in the document users.
          </div>
          <el-input
            class="search px-0"
            placeholder="Search"
            prefix-icon="el-icon-search"
            size="small"
            v-model="search_string"
            clearable
            :style="getIsMobile ? 'width:100px' : 'width:170px;'"
          >
          </el-input>
        </el-tooltip>
      </el-col>
      <el-col
        :xl="4"
        :lg="4"
        :md="6"
        :sm="12"
        :xs="12"
        v-if="checkPermissions('createNewDocument')"
      >
        <el-button
          :style="
            getIsMobile
              ? 'width:60px;margin-top:10px;position:relative;right:40px;'
              : 'width:190px;'
          "
          :class="getIsMobile ? 'mr-1' : 'create-btn px-4 ml-2'"
          type="primary"
          @click="goToCreateDocument"
          class="scaling-button"
        >
          <span class="create-doc">
            <i class="fa fa-plus-square-o"></i>
            <span :class="getIsMobile ? 'hidden-sm-and-down' : ''">
              Create Document
            </span>
          </span>
        </el-button>
      </el-col>
    </el-row>
    <dialog-component
      class="DialogueStyle"
      :title="'Warning'"
      :visible="centerDialogVisible"
      :containerWidth="getIsMobile ? '100%' : '25%'"
      @before-close="centerDialogVisible = false"
    >
      <span v-if="this.selected_document.length == 1"
        >Are you sure you want to delete 1 selected Document?</span
      >
      <span
        v-if="
          this.selected_document.length == 0 ||
          this.selected_document.length > 1
        "
        >Are you sure you want to delete
        {{ this.selected_document.length }} selected Documents?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedDocumentDelete"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      class="DialogueStyle"
      :title="'Warning'"
      :visible="dialoguevisibility"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      :containerHeight="'180px'"
      @before-close="dialoguevisibility = false"
      center
    >
      <div v-if="this.selected_document.length >= 1" class="mt-4">
        Only Document Owner can delete selected documents.
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialoguevisibility = false">Cancel</el-button>
        <el-button type="primary" @click="dialoguevisibility = false"
          >Ok</el-button
        >
      </span>
    </dialog-component>

    <DocumentsList
      page_size="10"
      :selectedDocStatus="selectedStatus"
      :search_string="search_string"
      :selectedFilter="selectedFilter"
      :selectedDate="selectedDate"
      :selectedDateRange="selectedDateRange"
      :selected_company_user="selected_company_user"
      @deleteEvent="fetchingSelectedDelete"
    ></DocumentsList>
  </section>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import DocumentsList from "@/components/companyDocuments/DocumentsList";
import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "companyDocuments-AllDocuments",
  props: ["pageSizes", "type", "categoryId", "groupId"],
  components: { DocumentsList },
  data() {
    return {
      is_documentDelete: false,
      dialoguevisibility: false,
      centerDialogVisible: false,
      isVisible: false,
      selected_document: [],
      loading: false,
      search_string: "",

      editDailogVisible: false,
      selected_company_user: "",
      companyUsers: [],
      selectedCompanyDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      page: 1,
      configureLoading: false,
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      documents: [],
      modalData: {
        document_id: "",
        name: "",
        action: "",
      },
      documentActionModal: false,
      modalLoading: false,
      selectedStatus: "",
      selectedFilter: "",
      selectedDate: "",
      selectedDateRange: "",
      filterByOption: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Yesterday",
          value: "yesterday",
        },
        {
          name: "This Week",
          value: "this_week",
        },
        {
          name: "Last Week",
          value: "last_week",
        },
        {
          name: "This Month",
          value: "this_month",
        },
        {
          name: "Last Month",
          value: "last_month",
        },
        {
          name: "This Quarter",
          value: "this_quarter",
        },
        {
          name: "Last Quarter",
          value: "last_quarter",
        },

        {
          name: "This Year",
          value: "this_year",
        },
        {
          name: "Last Year",
          value: "last_year",
        },
        {
          name: "Custom Date",
          value: "custom_date",
        },
        {
          name: "Custom Date Range",
          value: "custom_date_range",
        },
      ],
      statusOption: [
        { name: "Draft", value: "draft-docs" },
        { name: "Sent", value: "sent-docs" },
        { name: "Approved", value: "approved" },
        { name: "Company Documents", value: "company-documents" },
        { name: "Declined", value: "declined" },
        { name: "Voided", value: "voided" },
        { name: "Archived", value: "deleted" },
        { name: "Completed", value: "completed" },
        { name: "Waiting for Me", value: "waiting-for-me" },
        { name: "Waiting for Others", value: "waiting-for-others" },
        { name: "Expired", value: "expired" },
        { name: "Expiring Soon", value: "expiring-soon" },
      ],
      statusOptionIndividual: [
        { name: "Draft", value: "draft-docs" },
        { name: "Sent", value: "sent-docs" },
        { name: "Approved", value: "approved" },
        { name: "Declined", value: "declined" },
        { name: "Voided", value: "voided" },
        { name: "Archived", value: "deleted" },
        { name: "Completed", value: "completed" },
        { name: "Waiting for Me", value: "waiting-for-me" },
        { name: "Waiting for Others", value: "waiting-for-others" },
        { name: "Expired", value: "expired" },
        { name: "Expiring Soon", value: "expiring-soon" },
      ],
    };
  },
  mixins: [PermissionsHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserType",
      "getActiveWorkspace",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("documents", ["getAllCompanyUsers"]),
    getSelecetedDocStatus() {
      if (this.selecetedDocStatus == "waiting") {
        return "Need to be Sign";
      }
      return this.selecetedDocStatus;
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.type) {
      this.selectedStatus = this.$route.query.type;
    }
    this.getCompanyUsers();
  },
  methods: {
    async getCompanyUsers() {
      let params = {};
      params.company_id =
        this.getActiveWorkspace && this.getActiveWorkspace.company_id;
      await this.$store.dispatch("documents/fetchAllCompanyUsers", params);
      let companyUserName = " ";
      let fullName =
        this.getAuthenticatedUser.first_name +
        " " +
        this.getAuthenticatedUser.last_name;
      if (this.getAllCompanyUsers && this.getAllCompanyUsers.data) {
        let companyUsersData = this.getAllCompanyUsers.data;
        this.companyUsers = companyUsersData.filter((el) => {
          companyUserName = el.first_name + " " + el.last_name;
          return companyUserName != fullName;
        });
      }
    },
    setLoading() {
      bus.$emit("documentLoading", this.is_documentDelete);
    },
    goToCreateDocument() {
      if (!this.checkPermissionByPermissionName("createNewDocument")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for create document. please contact owner.",
        });
      } else {
        this.$router.push({
          name: "upload-documents",
        });
      }
    },
    fetchingSelectedDelete({ data }) {
      this.selected_document = [];
      this.selected_document = data;
    },
    checkCondition() {
      if (!this.checkPermissions("deleteDocument")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for delete document. Please contact owner.",
        });
      } else {
        this.selected_document.forEach((e) => {
          const authenticatedUser =
            this.getAuthenticatedUser && this.getAuthenticatedUser._id;
          if (
            e &&
            e.created_by &&
            authenticatedUser &&
            e.created_by === authenticatedUser
          ) {
            this.centerDialogVisible = true;
          } else if (
            e &&
            e.created_by &&
            authenticatedUser &&
            e.created_by !== authenticatedUser
          ) {
            this.dialoguevisibility = true;
            // this.$message({
            //   showClose: true,
            //   message: "You can only delete the documents which are created by you.",
            //   type: "warning",
            // });
          } else {
            this.centerDialogVisible = true;
          }
        });
      }
    },
    async selectedDocumentDelete() {
      this.centerDialogVisible = false;
      this.is_documentDelete = true;
      this.setLoading();
      this.selected_document.forEach(async (e, index) => {
        if (e.document_status == "ARCHIVED") {
          this.isVisible = true;
          if (
            e &&
            this.getAuthenticatedUser &&
            e.created_by === this.getAuthenticatedUser._id
          ) {
            let document_id = e._id;
            await this.$store.dispatch("documents/parmanentdelete", {
              document_id,
            });
            if (index == this.selected_document.length - 1) {
              this.$notify.success({
                title: "Success",
                message: "Documents deleted successfully",
              });
              //  window.location.reload();
            }
          }
        } else {
          // if(this.e.document_status != 'ARCHIVED'){
          // 	this.$message
          // }
          if (
            e &&
            this.getAuthenticatedUser &&
            e.created_by === this.getAuthenticatedUser._id
          ) {
            this.loading = true;
            let document_id = e._id;
            await this.$store.dispatch("documents/archiveDocument", {
              document_id,
            });
            if (index == this.selected_document.length - 1) {
              this.$notify.success({
                title: "Success",
                message: "Documents deleted successfully",
              });

              //window.location.reload();
            }
          }
        }
        this.setLoading();
      });
    },

    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.all-documents-view {
  .search {
    position: relative;
    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .select {
    position: relative;
    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .title {
    letter-spacing: 0.019em;
  }
  .text-secondary-sent {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    text-align: left !important;
    color: #439ffc !important;
  }
  .text-secondary {
    font-size: 14px !important;
    font-weight: 400 !important;
    // line-height: 14px !important;
    text-align: left !important;
    color: #6c757d !important;
  }
  .doc-subs {
    font-size: 10px;
    margin-top: -13px;
    font-weight: 300;
    text-align: right;
    margin-right: 9%;
  }
  .doc-sub {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    //margin-bottom:-18px;
    text-align: left;
  }
  .doc-title {
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 14px;
    text-align: left;

    color: #6c757d !important;
  }
  .check-font {
    color: #6c757d !important;
    font-family: "Inter", sans-serif;
  }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #f19203;
    }
    &.sent::after {
      background: #2f80ed;
    }
    &.active::after {
      background: #635e5e;
    }
    &.expired::after {
      background: #f03232;
    }
  }
  .custom-pagination {
    text-align: center;
    margin-top: 5px;
  }
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  scrollbar-width: thin;
}
</style>

<style lang="scss">
.doc-modal {
  .el-dialog__header {
    .el-dialog__title {
      text-transform: uppercase;
    }
  }
  .error {
    color: red;
  }
}
.import-dropdown-btn {
  margin-left: 10px;
  height: 40px;
  .el-button {
    height: 100%;
    font-size: 14px;
    span {
      padding: 0 10px;
    }
  }
}
.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;
    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }
    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}
.DialogueStyle {
  margin-top: 50px;
}
</style>

<style>
.scaling-button {
  transform: scale(1);
  transition: transform 0.3s;
  height: 32px;
}

.scaling-button:hover {
  transform: scale(1.03);
}
</style>
<style scoped>
.import-dropdown-btn .el-button {
  height: 100%;
  font-size: 14px;
  position: relative !important;
  bottom: 10px !important;
}
.create-doc {
  position: relative;
  bottom: 5px;
}
@media (max-width: 768px) {
  .ml-0 {
    margin-left: 0 !important;
  }
  .mb-2 {
    margin-bottom: 10px;
  }
}
</style>
