<template>
  <section id="global-variables-list">
    <el-row v-loading="loading" type="flex" justify="center" :gutter="40">
      <el-col :span="16">
        <h2 class="h2 f-weight-400 mt-0 d-flex align-center pl-3">
          <div class="circle-icon-2" :style="`background: ${iconBgColor} !important;`">
            <img
              src="@/assets/img/icons/menuApplicationUsersWhite/dashboard.svg"
              alt="menu"
              width="30"
              style="height: 22px; width: 22px; margin-top: 6px !important"
              v-if="iconColor == 'White'"
            />
            <img
              src="@/assets/img/icons/menuApplicationUsers/dashboard.svg"
              alt="menu"
              width="30"
              style="height: 22px; width: 22px; margin-top: 6px !important"
              v-else
            />
          </div>
          <span class="text pl-1">Menu Management</span>
        </h2>
        <div class="user-menu-container mt-20 ml-3">
          <!-- <el-button
            type="primary"
            class="menuPreviewButton"
            icon="el-icon-video-play"
            >Preview</el-button
          >-->
          <el-button
            type="primary"
            class="menuSubmitButton"
            icon="el-icon-check"
            v-if="data.length"
            @click="saveMenuList"
          >Save</el-button>
          <!-- <el-button type="danger" class="menuSubmitButton" icon="el-icon-check">Delete Menu</el-button>-->
          <div class="sidemenu">
            <el-tree
              :data="data"
              default-expand-all
              :expand-on-click-node="false"
              :props="defaultProps"
              draggable
              class="mt-3"
              v-if="this.data.length && !refresh"
              :allow-drop="allowDrop"
            >
              <template class="custom-tree-node" slot-scope="{ node, data }">
                <div>
                  <el-card shadow="never" class="each-item pt-0">
                    <div>
                      <div class="d-flex">
                        <div
                          class="circle-icon-2"
                          :style="`background: ${iconBgColor} !important;`"
                        >
                          <img
                            :src="
                              require('@/assets/img/icons/' +
                                getParentPath(data.icon_category) +
                                '/' +
                                data.icon)
                            "
                            alt="menu"
                            width="26"
                            style="
                              height: 22px;
                              width: 22px;
                              margin-top: 6px !important;
                            "
                          />
                        </div>
                        <h4 class="h4 f-weight-500 mt-0 d-flex align-center">
                          <span class="text pl-1">
                            {{ node.label }}
                            {{ data.title | truncate(19, "...") }}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div class="actions-btn float-right">
                      <el-button
                        type="text"
                        size="medium"
                        @click="moveToTop(data, node)"
                        :disabled="checkIsFirstNode(data, node)"
                      >
                        <i class="el-icon-top yale-blue"></i>
                      </el-button>
                      <el-button
                        type="text"
                        size="medium"
                        @click="moveToBottom(data, node)"
                        :disabled="checkIsLastNode(data, node)"
                      >
                        <i class="el-icon-bottom yale-blue"></i>
                      </el-button>

                      <el-button type="text" size="medium" @click="editMenuItem(data, node)">
                        <i class="el-icon-edit warning"></i>
                      </el-button>

                      <el-button type="text" size="medium" @click="deleteMenuItem(data, node)">
                        <i class="el-icon-delete tomato"></i>
                      </el-button>
                    </div>
                  </el-card>
                </div>
              </template>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never" class="mt-2">
          <el-form v-model="form">
            <el-form-item class="mb-10" label="Title">
              <br />
              <el-input placeholder="Please Enter title" v-model="form.title"></el-input>
            </el-form-item>

            <el-form-item class="mb-10" label="Type">
              <el-radio-group v-model="form.type" @change="fetchParents">
                <el-col :span="12">
                  <el-radio label="Individual"></el-radio>
                </el-col>
                <el-col :span="6">
                  <el-radio label="Parent"></el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio label="SubParent"></el-radio>
                </el-col>
                <el-col :span="6">
                  <el-radio label="Child"></el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Feature"
              v-if="form.type != 'Parent' && form.type != 'SubParent'"
            >
              <el-select v-model="form.feature" placeholder="Select Feature">
                <el-option
                  v-for="(op, i) in features"
                  :key="i + op"
                  :value="op.value"
                  :label="op.title"
                >{{ op.title }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-15"
              label="Select Parent"
              v-if="form.type == 'Child' || form.type == 'SubParent'"
            >
              <el-select v-model="form.parent" placeholder="Select Parent">
                <el-option
                  v-for="(item, index) in parents"
                  :key="index"
                  :label="item.title"
                  :value="item.title"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Form builder type"
              v-if="form.feature == 'form_builder'"
            >
              <el-select v-model="form.form_builder_type" clearable>
                <el-option value="ALL" label="All"></el-option>
                <el-option value="FILLED_BY_ME" label="Filled by me"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Entity type"
              v-if="
                form.feature == 'entities' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select placeholder="Select Entity" v-model="form.entity_type">
                <el-option value="ENTITY" label="Entity">Entity</el-option>
                <el-option value="RELATIONAL_ENTITY" label="Relational Entity">Relational Entity</el-option>
                <el-option
                  value="NESTED_RELATIONAL_ENTITY"
                  label="Nested relational Entity"
                >Nested relational Entity</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Form builder"
              v-if="
                form.feature == 'form_builder' &&
                form.form_builder_type &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select
                placeholder="Select form builder"
                v-model="form.formbuilder_id"
                @change="setFormbuilderDetails"
              >
                <el-option
                  v-for="(op, i) in allFormBuilders"
                  :key="op._id + i"
                  :value="op._id"
                  :label="op.name"
                >{{ op.name }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Entity"
              v-if="
                form.feature == 'entities' &&
                form.entity_type == 'ENTITY' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select placeholder="Select Entity" v-model="form.entity_id" @change="getFeatures">
                <el-option
                  v-for="(op, i) in getRelatedEntities"
                  :key="op._id + i"
                  :value="op._id"
                  :label="op.name"
                >{{ op.name }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Entity View Type"
              v-if="
                form.feature == 'entities' &&
                form.entity_type == 'ENTITY' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <br />
              <el-select
                v-model="form.entity_view_type"
                placeholder="Select Entity ViewType"
                filterable
              >
                <el-option
                  v-for="viewType in entityViewTypes"
                  :key="viewType.key"
                  :label="viewType.label"
                  :value="viewType.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Relational entity"
              v-if="
                form.feature == 'entities' &&
                form.entity_type == 'RELATIONAL_ENTITY' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select
                placeholder="Relational Entity"
                v-model="form.relational_entity"
                @change="getFeatures"
              >
                <el-option
                  v-for="(op, i) in getRelationshipEntities"
                  :key="op._id + i"
                  :value="op._id"
                  :label="op.name"
                >{{ "My " + op.name }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Entity views"
              v-if="
                form.feature == 'entity_views' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <br />
              <el-select v-model="form.entityviews_id" placeholder="Select entity view " filterable>
                <el-option
                  v-for="view in allEntityViews"
                  :key="view._id"
                  :label="view.name"
                  :value="view._id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Select a form template"
              v-if="
                form.feature == 'a_form_template' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select v-model="form.template_id" placeholder="Select a form template" filterable>
                <el-option
                  v-for="template in allFormTemplates.filter(
                    (e) => e.type != 'STANDARD'
                  )"
                  :key="template._id"
                  :label="template.name"
                  :value="template._id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Nested Relational entity"
              v-if="
                form.feature == 'entities' &&
                form.entity_type == 'NESTED_RELATIONAL_ENTITY' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select
                placeholder="Nested Relational Entity"
                v-model="form.nested_relational_entity"
                @change="
                  getFeatures(form.nested_relational_entity.split('#')[1])
                "
              >
                <el-option
                  v-for="(op, i) in getNestedRelationshipEntities"
                  :key="op.id + i"
                  :value="op.key"
                  :label="op.relationship_title"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Page Dashboards"
              v-if="
                form.feature == 'page_dashboard' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select
                placeholder="Select Dashboard"
                v-model="form.page_dashboard"
                filterable
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(dashboard, index) in getEntityUserDashboardsList"
                  :key="index"
                  :value="dashboard._id + '#' + dashboard.name"
                  :label="dashboard.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Entity Filters"
              v-model="form.filters"
              v-if="
                form.feature == 'entities' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select placeholder="Select Entity Filter" v-model="form.filters" clearable>
                <el-option
                  v-for="filter in entityAllFilters"
                  :key="filter._id"
                  :label="filter.filter_name"
                  :value="filter._id"
                >{{ filter.filter_name }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-10"
              label="Entity Customization"
              v-model="form.filters"
              v-if="
                form.feature == 'entities' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
            >
              <el-select
                placeholder="Select Entity Customization"
                v-model="form.customization"
                clearable
              >
                <el-option
                  v-for="customization in entityAllCustomizations"
                  :key="customization._id"
                  :label="customization.label"
                  :value="customization._id"
                >{{ customization.label }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                form.feature == 'entities' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
              class="mb-10"
            >
              <el-checkbox v-model="form.redirectToAdd">Redirect to ADD</el-checkbox>
            </el-form-item>
            <el-form-item
              class="mb-10"
              v-if="
                form.feature == 'entities' &&
                form.type != 'Parent' &&
                form.type != 'SubParent'
              "
              label="Redirect Menu Item"
            >
              <el-tooltip
                content="After adding the data to the entity, the page will redirect to the selected menu item."
              >
                <i class="el-icon-info" style="margin-left: 5px"></i>
              </el-tooltip>
              <el-select
                v-model="form.redirectMenuItem"
                placeholder="Select menu Item"
                filterable
                clearable
              >
                <el-option
                  v-for="(menu, index) in getAllMenuItems"
                  :key="index"
                  :value="menu._id"
                  :label="menu.title"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="form.feature || form.type == 'Parent'" label="Show menu-item when:">
              <el-select
                v-model="form.selected_entity_filter"
                placeholder="Select a filter"
                filterable
                clearable
              >
                <el-option
                  v-for="filter of currentEntityFilters"
                  :key="filter._id"
                  :value="filter._id"
                  :label="filter.filter_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                form.feature &&
                form.feature == 'entities' &&
                form.entity_type == 'RELATIONAL_ENTITY' &&
                form.relational_entity
              "
              label="Self related Filter :"
            >
              <el-select v-model="form.self_related_filter" placeholder="Self related Filter">
                <el-option value="login_user" label="Login User"></el-option>
                <el-option
                  v-for="item in getEntityFieldsCurrentEntity"
                  :key="item.entity_id"
                  :label="item.label"
                  :value="item.entity_id + '#' + item.template_key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Menu Icon">
              <el-button type="text" class="text-center" @click="iconPickerdialogVisible = true">
                <div class="circle-icon-2" :style="`background: ${iconBgColor} !important;`">
                  <img
                    :src="
                      require('@/assets/img/icons/' +
                        getParentPath(form.icon_category) +
                        '/' +
                        form.icon)
                    "
                    alt="menu"
                    width="26"
                    style="
                      height: 22px;
                      width: 22px;
                      margin-top: 6px !important;
                    "
                  />
                </div>
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                class="mt-10"
                type="primary"
                :disabled="checkFormDisabled"
                @click="onMenuItemSubmit"
              >Add</el-button>

              <el-button @click="resetFormData">Cancel</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <span v-if="getEntityMenu">
          <label class="ml-1 mt-1">Select Main Route</label>
          <br />
          <el-select
            v-model="main_route"
            placeholder="Select menu Item"
            filterable
            clearable
            class="ml-1"
            @change="handleMainRouteChange()"
          >
            <el-option
              v-for="(menu, index) in getAllMenuItems"
              :key="index"
              :value="menu._id"
              :label="menu.title"
            ></el-option>
          </el-select>
        </span>
      </el-col>
    </el-row>
    <dialog-component
      :title="'Select Menu Icon'"
      :visible="iconPickerdialogVisible"
      top="25px"
      :containerWidth="'50%'"
      :containerHeight="'65%'"
      @before-close="iconPickerdialogVisibleDialog"
      :isShowFooter="false"
    >
      <div class="iconslistScrollable">
        <div class="mt-20">
          <el-input placeholder="Search by name" v-model="searchIcon" clearable class="mb-10">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <el-row>
          <el-tabs v-model="selectedCategory">
            <el-tab-pane
              :label="category.name"
              :name="category.key"
              v-for="(category, i) in menuIcons"
              :key="category.key + '_' + i"
            ></el-tab-pane>
          </el-tabs>
        </el-row>

        <el-row :gutter="10" style="margin-top: 15px">
          <el-col
            :xs="4"
            :sm="3"
            :md="2"
            :lg="2"
            :xl="1"
            v-for="(icon, index) in getSelectedCategory"
            :key="index"
            class="border-radius"
          >
            <div class="grid-content ep-bg-purple" @click="setIcon(icon)" style="cursor: pointer">
              <el-tooltip effect="dark" :content="icon.name" placement="top-start">
                <div class="circle-icon" :style="`background: ${iconBgColor} !important;`">
                  <img
                    :src="
                      require('@/assets/img/icons/' +
                        getParentPath(selectedCategory) +
                        '/' +
                        icon.url)
                    "
                    width="25"
                    height="27"
                    style="margin-top: 10px !important"
                  />
                </div>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Main Route'"
      :visible="mainRouteDialog"
      @before-close="closeDialog"
      :containerWidth="'22%'"
      :containerHeight="'40%'"
    >
      <span>
        <h5 class="mt-20 ml-1">Please select Main Route</h5>
        <el-select
          v-model="main_route"
          placeholder="Select menu Item"
          filterable
          clearable
          class="ml-1"
          @change="handleMainRouteChange()"
        >
          <el-option
            v-for="(menu, index) in getAllMenuItems"
            :key="index"
            :value="menu._id"
            :label="menu.title"
          ></el-option>
        </el-select>
      </span>
      <span></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" plain>Cancel</el-button>
        <el-button type="primary" @click="saveMainRoute">Apply</el-button>
      </span>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { fetchFormBuildersByPagination } from "@/repo/formbuilderDetails";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
//var menuIconsData = require("@/assets/data/menu_icons.json");
var menuIconsData = require("@/assets/data/menu_icons_application.json");
var menuIconsDataWhite = require("@/assets/data/menu_icons_application_white.json");
import { fetchEntityById } from "@/repo/entityRepo";
import EntitiesHelper from "../../mixins/EntitiesHelper";
export default {
  props: ["entity_id", "selectedEntityId"],
  mixins: [TemplateBuilderHelper, EntitiesHelper],
  data() {
    return {
      theme: "Dark Icons",
      searchingString: "",
      loading: false,
      selectedFeature: "",
      iconPickerdialogVisible: false,
      menuIcons: menuIconsData.categories,
      menuIconsWhite: menuIconsDataWhite,
      selectedCategory: "default",
      iconsData: [],
      searchIcon: "",
      searchIconResult: [],
      currentEntityFields: [],
      parents: [],
      childEditIndex: -1,
      subParentEditIndex: -1,
      subparent: [],
      subChildIndex: -1,
      features: [
        {
          title: "Dashboard",
          value: "dashboard"
        },
        {
          title: "Page Dashboard",
          value: "page_dashboard"
        },
        {
          title: "Form builder",
          value: "form_builder"
        },
        {
          title: "My form builders",
          value: "my_form_builders"
        },
        {
          title: "My documents",
          value: "my_documents"
        },
        {
          title: "My workflows",
          value: "my_workflows"
        },
        {
          title: "My Approval Forms",
          value: "my_approval_forms"
        },
        {
          title: "Entities",
          value: "entities"
        },
        {
          title: "Entity Views",
          value: "entity_views"
        },
        {
          title: "A Form template",
          value: "a_form_template"
        }
      ],
      defaultProps: {
        children: "children",
        title: "title"
      },
      data: [],
      refresh: false,
      entityAllFilters: [],
      entityAllCustomizations: [],
      form: {
        title: "",
        feature: "",
        category: "",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        permission: "",
        isGroup: "",
        groups: "",
        entity_id: "",
        type: "Individual",
        relational_entity: "",
        nested_relational_entity: "",
        page_dashboard: [],
        entity_type: "ENTITY",
        has_dark_icons: false,
        form_builder_type: "ALL",
        customization: "",
        redirectMenuItem: "",
        entity_view_type: "",
        selected_entity_filter: null,
        isChecked: false,
        self_related_filter: "login_user"
      },
      relationshipEntities: [],
      nestedRelationshipEntities: [],
      permittedEntities: [],
      permittedRelatedEntities: [],
      permittedNestedRelatedEntities: [],
      selectedMenuId: null,
      iconBgColor: "",
      iconColor: "White",
      allFormBuilders: [],
      allEntityViews: [],
      allFormTemplates: [],
      redirectToAdd: false,
      entityViewTypes: [
        {
          label: "Table",
          key: "TABLE"
        },
        {
          label: "Board",
          key: "BOARD"
        },
        {
          label: "Cards",
          key: "CARDS"
        },
        {
          label: "Charts",
          key: "CHARTS"
        },
        {
          label: "Content",
          key: "CONTENT"
        }
      ],
      currentEntityFilters: [],
      main_route: "",
      mainRouteDialog: false
    };
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById", "getAllEntities"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityNestedRelationships"
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus"
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getDeleteMenuStatus",
      "getMenuList",
      "getEntityMenu"
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo"
    ]),
    ...mapGetters("entityViews", ["getAllEntityViews"]),
    ...mapGetters("formBuilders", ["getAllFormBuildersData"]),
    ...mapGetters("customDashboard", ["getEntityUserDashboardsList"]),
    ...mapGetters("entityCustomization", ["getAllCustomizations"]),
    filteredIconsData() {
      // if (this.selectedCategory) {
      //   const category = this.menuIcons.categories.find(
      //     (category) => category.name === this.selectedCategory
      //   );
      //   return category ? category.icons : [];
      // } else {
      return [];
      // }
    },
    getAllMenuItems() {
      let menus = [];
      this.data.map(menu => {
        if (
          !menu.children.length &&
          (menu.feature !== "ENTITIES" || menu.entity_id != this.form.entity_id)
        ) {
          menus.push(menu);
        } else {
          menu.children.map(sub => {
            if (
              (!sub.children || !sub.children.length) &&
              (sub.feature !== "ENTITIES" ||
                sub?.entity_id != this.form.entity_id)
            ) {
              menus.push(sub);
            } else {
              sub.children.map(sub1 => {
                if (
                  sub1.feature !== "ENTITIES" ||
                  sub1?.entity_id != this.form.entity_id
                ) {
                  menus.push(sub1);
                }
              });
            }
          });
        }
      });
      return menus;
    },
    getRelatedEntities() {
      if (this.getAllEntities && this.getAllEntities.data) {
        if (this.getEntityPermission?._id) {
          return this.getAllEntities.data.filter(
            e => this.permittedEntities.indexOf(e._id) != -1
          );
        }
        return this.getAllEntities.data.filter(
          e => e._id == this.selectedEntityId
        );
      }
      return [];
    },
    getRelationshipEntities() {
      if (this.getEntityPermission?._id) {
        return this.getAllEntities.data.filter(
          e => this.permittedRelatedEntities.indexOf(e._id) != -1
        );
      }
      if (this.relationshipEntities && this.relationshipEntities.length) {
        return this.getAllEntities.data.filter(
          e => this.relationshipEntities.indexOf(e._id) != -1
        );
      }
      return [];
    },
    getNestedRelationshipEntities() {
      if (this.getEntityPermission?._id) {
        return this.nestedRelationshipEntities.filter(
          e => this.permittedNestedRelatedEntities.indexOf(e.key) != -1
        );
      }
      return [];
    },
    checkFormDisabled() {
      if (this.form.type === "Parent" && this.form.title) {
        return false;
      }
      return (
        !this.form.title ||
        !this.form.type ||
        (!this.form.feature &&
          this.form.type != "Parent" &&
          !(this.form.type == "SubParent" && this.form.parent)) ||
        (this.form.feature == "entities" &&
          ((this.form.entity_type == "ENTITY" && !this.form.entity_id) ||
            (this.form.entity_type == "RELATIONAL_ENTITY" &&
              !this.form.relational_entity))) ||
        (this.form.type == "Child" && !this.form.parent)
      );
    },
    // parents() {
    //   if (this.data) {
    //     return this.data.filter(
    //       (x, i) => x.type == "Parent" && i != this.editIndex
    //     );
    //   }
    //   return [];
    // },
    getSelectedCategory() {
      let selectedIcons = this.menuIcons.find(
        e => e.key == this.selectedCategory
      );
      if (selectedIcons?.icons) {
        if (this.searchIcon) {
          return selectedIcons.icons.filter(e =>
            e.name.toLowerCase().includes(this.searchIcon.toLowerCase())
          );
        }
        return selectedIcons.icons;
      }
      return [];
    },
    getEntityFieldsCurrentEntity() {
      console.log("zxcvbnm", this.currentEntityFields);
      if (!this.currentEntityFields?.length) return [];
      return (
        (this.currentEntityFields || []).filter(
          e => e.input_type == "ENTITY"
        ) || []
      );
    }
  },
 
  async mounted() {
    if (this.selectedEntityId) {
      this.loading = true;
      const [
        getAllFormBuildersData,
        allFormTemplates,
        currentEntityFields
      ] = await Promise.all([
        fetchFormBuildersByPagination({
          get_all: true
        }),
        this.fetchAllTemplates(),
        this.fetchEntityDetails(this.selectedEntityId, true),
        this.fetchEntityViews(),
        this.$store.dispatch(
          "applicationUsersPermission/fetchPermissionsEntityById",
          { id: this.selectedEntityId }
        ),
        this.$store.dispatch("entities/fetchEntities", {
          get_all: true,
          populateData: true
        }),
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: this.selectedEntityId
        }),
        this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
          get_all: true,
          include_standard: true,
          populateData: true
        }),
        this.$store.dispatch(
          "menuManagementV2/fetchMenuByEntity",
          this.selectedEntityId
        ),
        this.$store.dispatch("formBuilders/fetchAllFormBuilders", {
          get_all: true,
          entity_id: this.selectedEntityId,
          form_builders_owner: "ENTITY"
        }),
        this.$store.dispatch(
          "entityRelationships/fetchEntityNestedRelationships",
          {
            entity_id: this.selectedEntityId,
            fetchAll: true
          }
        ),
        this.$store.dispatch(
          "customDashboard/fetchEntityUserDashboardsList",
          this.selectedEntityId
        )
      ]);
      this.currentEntityFields = currentEntityFields;
      this.allFormTemplates = allFormTemplates;
      if (this.getEntityRelationships) {
        this.setRelationshipPermissions();
      }
      if (this.getEntityPermission) {
        this.setPermittedEntities();
      }
      if (this.getEntityMenu && this.getEntityMenu) {
        this.selectedMenuId = this.getEntityMenu;
        this.setMenuData(this.getEntityMenu);
      } else {
        this.setStandardMenu();
      }
      if (getAllFormBuildersData?.data) {
        this.allFormBuilders = getAllFormBuildersData.data;
      }
      if (this.getCompanyDetails) {
        console.log("getCompanyDetails", this.getCompanyDetails);
      }
      this.setIconBgColor();
      this.iconColor = this.getCompanyDetails.icon_color;
      this.getIconsByCategory();
      this.currentEntityFilters = await this.getCurrentEntityFilters(
        this.selectedEntityId
      );
      this.loading = false;
    }
  },
  methods: {
    updateCheckedStatus(items, mainRouteId) {
      items.forEach(item => {
        item.isChecked = item._id === mainRouteId;
        if (item.children && item.children.length > 0) {
          this.updateCheckedStatus(item.children, mainRouteId);
        }
      });
    },
    handleMainRouteChange() {
      if (!this.main_route) {
        this.updateCheckedStatus(this.data, null);
      } else {
        this.updateCheckedStatus(this.data, this.main_route);
      }
      if (this.mainRouteDialog == false) {
        this.$message({
          message: " Please Save the menu",
          type: "info",
          duration: 2000
        });
      }
    },
    iconPickerdialogVisibleDialog() {
      this.iconPickerdialogVisible = false;
    },
    async fetchEntityViews() {
      try {
        this.loading = true;
        let params = {
          get_all: true
        };
        await this.$store.dispatch("entityViews/getAllEntityViews", params);
        if (this.getAllEntityViews) {
          this.allEntityViews =
            this.getAllEntityViews && this.getAllEntityViews.data
              ? this.getAllEntityViews.data
              : [];
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (err) {
        console.log("fetchEntityViews", err);
        this.loading = false;
      }
    },
    setFormbuilderDetails(value) {
      if (value) {
        let selectedForm = this.allFormBuilders.find(e => e._id == value);
        this.form.form_builder_code = selectedForm.code || "";
      } else {
        this.form.form_builder_code = "";
      }
    },
    async fetchParents() {
      if (this.form.type === "Child") {
        this.parents = this.data.filter(
          (x, i) => x.type == "Parent" && i != this.editIndex
        );
        let subparents = [];
        await this.parents.forEach(parent => {
          if (parent.children && parent.children.length) {
            parent.children.forEach(child => {
              if (child.type == "SubParent") {
                subparents.push(child);
              }
            });
          }
        });
        this.parents = [...this.parents, ...subparents];
        // this.subparent = this.data.filter(
        //   (x, i) => x.type == "SubParent" && i != this.editIndex
        // );
      } else if (this.form.type === "SubParent") {
        this.parents = this.data.filter(
          (x, i) => x.type == "Parent" && i != this.editIndex
        );
      } else {
        this.parents = [];
      }
    },
    getParentPath(selectedCategory) {
      if (selectedCategory == "default" || !selectedCategory) {
        if (this.iconColor == "White") {
          return "menuApplicationUsers" + "White";
        } else {
          return "menuApplicationUsers";
        }
      } else {
        if (this.iconColor == "White") {
          return selectedCategory + "-" + "White";
        } else {
          return selectedCategory + "-" + "black";
        }
      }
    },
    getIconsByCategory() {
      this.iconsData = this.filteredIconsData;
    },
    setStandardMenu() {
      this.data.push({
        children: [],
        title: "Dashboard",
        feature: "dashboard",
        category: "",
        icon: "dashboard.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
        has_dark_icons: true
      });
      this.data.push({
        children: [],
        title: "My Documents",
        feature: "my_documents",
        category: "",
        icon: "File.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
        has_dark_icons: true
      });
      this.data.push({
        children: [],
        title: "My Workflows",
        feature: "my_workflows",
        category: "",
        type: "Individual",
        icon: "Workflows.svg",
        isGroup: "",
        has_dark_icons: true
      });
      this.data.push({
        title: "My form builders",
        feature: "my_form_builders",
        category: "",
        parent: "",
        icon: "Documentation.svg",
        children: [],
        permission: "",
        isGroup: "",
        type: "Individual",
        has_dark_icons: true
      });
      if (this.relationshipEntities && this.relationshipEntities.length) {
        this.relationshipEntities.forEach(e => {
          let entity =
            this.getAllEntities && this.getAllEntities.data
              ? this.getAllEntities.data.find(el => e == el._id)
              : null;
          this.data.push({
            entity_type: "RELATIONAL_ENTITY",
            children: [],
            title: "My " + (entity && entity.name ? entity.name : "data"),
            feature: "entities",
            category: "",
            icon: "MultipleUsers.svg",
            permission: "",
            isGroup: "",
            relational_entity: e,
            type: "Individual"
          });
        });
      }
    },
    setMenuData(previousMenuData) {
      let data = previousMenuData
        ? previousMenuData.menu_list.map(e => {
            if (e.entity_id && e.entity_id._id) {
              e.entity_id = e.entity_id._id;
            }
            if (e.filters && e.filters._id) {
              e.filters = e.filters._id;
            }
            if (e.formbuilder_id && e.formbuilder_id._id) {
              e.formbuilder_id = e.formbuilder_id._id;
            }
            e.children = (e.children || []).map(el => {
              if (el.entity_id && el.entity_id._id) {
                el.entity_id = el.entity_id._id;
              }
              if (el.filters && el.filters._id) {
                el.filters = el.filters._id;
              }
              if (el.formbuilder_id && el.formbuilder_id._id) {
                el.formbuilder_id = el.formbuilder_id._id;
              }
              return el;
            });
            return e;
          })
        : [];
      this.data = this.filterNotPermittedEntities(data);
      this.theme = previousMenuData?.has_dark_icons
        ? "Dark Icons"
        : "Light Icons";
      this.main_route = previousMenuData.main_route;
    },
    filterNotPermittedEntities(data) {
      if (this.getEntityPermission?._id) {
        return data.filter(e => {
          if (e.feature == "entities") {
            if (e.entity_type == "ENTITY") {
              return this.permittedEntities.indexOf(e.entity_id) != -1
                ? true
                : false;
            } else if (e.entity_type == "RELATIONAL_ENTITY") {
              return this.permittedRelatedEntities.indexOf(
                e.relational_entity
              ) != -1
                ? true
                : false;
            }
          }
          return e;
        });
      }
      return data;
    },
    resetFormData() {
      this.form.title = "";
      this.form.feature = "";
      this.form.category = "";
      this.form.type = "";
      this.form.parent = "";
      this.form.icon = "dashboard.svg";
      this.form.children = [];
      this.form.permission = "";
      this.form.isGroup = "";
      this.form.groups = "";
      this.form.type = "Individual";
      this.form.relational_entity = "";
      this.form.nested_relational_entity = "";
      this.form.entity_type = "ENTITY";
      this.form.page_dashboard = [];
    },
    async saveMenuList() {
      this.loading = true;
      let params = {
        entity: this.selectedEntityId,
        menu_list: this.data.map(e => {
          if (e.groups == "") {
            delete e.groups;
          }
          if (e.filters == "") {
            delete e.filters;
          }
          if (e.customization == "") {
            delete e.customization;
          }
          if (e.groups == "") {
            delete e.groups;
          }
          if (e.children && e.children.length) {
            e.children.map(el => {
              if (el.groups == "") {
                delete el.groups;
              }
              if (el.filters == "") {
                delete el.filters;
              }
              if (el.customization == "") {
                delete el.customization;
              }
              if (el.children && el.children.length) {
                el.children.map(ele => {
                  if (ele.groups == "") {
                    delete ele.groups;
                  }
                  if (ele.filters == "") {
                    delete ele.filters;
                  }
                  if (ele.customization == "") {
                    delete ele.customization;
                  }
                  if (
                    ele.entity_type == "ENTITY" ||
                    ele.feature != "entities"
                  ) {
                    delete ele.relational_entity;
                    delete ele.nested_relational_entity;
                  }
                  if (
                    ele.entity_type == "RELATIONAL_ENTITY" ||
                    ele.feature != "entities"
                  ) {
                    delete ele.entity_id;
                  }
                  return ele;
                });
              }
              if (el.entity_type == "ENTITY" || el.feature != "entities") {
                delete el.relational_entity;
                delete el.nested_relational_entity;
              }
              if (
                el.entity_type == "RELATIONAL_ENTITY" ||
                el.feature != "entities"
              ) {
                delete el.entity_id;
              }
              if (e.entity_type == "NESTED_RELATIONAL_ENTITY") {
                delete e.entity_id;
                delete e.relational_entity;
              }
              return el;
            });
          }
          if (e.entity_type == "ENTITY" || e.feature != "entities") {
            delete e.relational_entity;
            delete e.nested_relational_entity;
          }
          if (e.entity_type == "RELATIONAL_ENTITY" || e.feature != "entities") {
            delete e.entity_id;
          }
          if (e.entity_type == "NESTED_RELATIONAL_ENTITY") {
            delete e.entity_id;
            delete e.relational_entity;
          }
          return e;
        }),
        type: "USER",
        has_dark_icons: this.theme == "Dark Icons" ? true : false
      };
      this.resetFormData();
      params["main_route"] = this.main_route;
      if (this.selectedMenuId) {
        params["_id"] = this.selectedMenuId;
        await this.$store.dispatch(
          "menuManagementV2/updateMenuManagement",
          params
        );
        if (this.getMenuManagementUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Menu Updated."
          });
          this.loading = false;
          this.close();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in updating menu ."
          });
          this.loading = false;
        }
        this.mainRouteDialog = false;
      } else {
        await this.$store.dispatch(
          "menuManagementV2/addMenuManagement",
          params
        );
        if (this.getMenuManagementAddStatus) {
          this.mainRouteDialog = true;
          this.$notify.success({
            title: "Success",
            message: "Menu Added."
          });
          this.loading = false;
          // this.close();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in adding menu ."
          });
          this.loading = false;
        }
      }
    },
     async saveMainRoute(){
       await this.saveMenuList()
      this.mainRouteDialog = false
      this.close()
    },
    closeDialog() {
      (this.mainRouteDialog = false), this.close();
    },
    allowDrop(draggingNode, dropNode, type) {
      if (
        !draggingNode?.childNodes.length &&
        draggingNode?.data?.type == "Parent" &&
        !draggingNode?.data?.feature
      ) {
        return false;
      } else if (
        !draggingNode?.childNodes.length &&
        draggingNode?.data?.type == "SubParent" &&
        !draggingNode?.data?.feature
      ) {
        return false;
      } else if (draggingNode?.childNodes.length) {
        if (
          draggingNode?.data?.children &&
          type != "inner" &&
          dropNode?.level < 3
        ) {
          return true;
        }
      } else if (dropNode?.level < 3) {
        if (dropNode.data.type == draggingNode.data.type && type == "inner") {
          return false;
        } else {
          return true;
        }
      } else if (
        dropNode?.level < 3 &&
        type != "inner" &&
        (!draggingNode?.data?.children || !draggingNode.data.children.length)
      ) {
        return true;
      } else if (
        dropNode?.level == 3 &&
        type != "inner" &&
        (!draggingNode?.data?.children ||
          !draggingNode?.data?.children.children ||
          !draggingNode.data.children.children.length)
      ) {
        return true;
      }
      return false;
    },
    close() {
      this.$emit("close");
    },
    setPermittedEntities() {
      this.permittedEntities = [];
      this.permittedRelatedEntities = [];
      this.permittedNestedRelatedEntities = [];
      if (this.getEntityPermission?.other_entities) {
        this.permittedEntities = [
          ...this.permittedEntities,
          ...Object.keys(this.getEntityPermission?.other_entities)
        ];
      }
      if (this.getEntityPermission?.relational_entities) {
        this.permittedRelatedEntities = [
          ...this.permittedRelatedEntities,
          ...Object.keys(this.getEntityPermission?.relational_entities)
        ];
      }
      if (this.getEntityPermission?.nested_relational_entities) {
        this.permittedNestedRelatedEntities = [
          ...this.permittedNestedRelatedEntities,
          ...Object.keys(this.getEntityPermission?.nested_relational_entities)
        ];
      }
    },
    setRelationshipPermissions() {
      this.getEntityRelationships.forEach(el => {
        if (el?.child_entity_id !== this.selectedEntityId) {
          this.relationshipEntities.push(el.child_entity_id);
        } else if (this.selectedEntityId !== el?.parent_entity_id) {
          this.relationshipEntities.push(el.parent_entity_id);
        }
      });
      this.getEntityNestedRelationships.forEach(e => {
        this.nestedRelationshipEntities.push({
          relationship_title:
            e.parent_relationship_details.relationship_title +
            "/" +
            e.relationship_title,
          key:
            e.parent_relationship_details.relationship_id +
            "#" +
            e.relationship_id,
          id: e._id
        });
      });
    },
    checkIsFirstNode(data, node = {}) {
      if (data.type == "Individual" || data.type == "Parent") {
        let currentIndex = this.data.findIndex(x => x.title == data.title);
        return currentIndex == 0;
      } else if (data.type === "Child") {
        if (node.level == 2) {
          let currentIndex = this.data.findIndex(
            x => x.title == node.parent.data.title
          );
          let childEditIndex = -1;
          if (currentIndex > -1) {
            childEditIndex = this.data[currentIndex].children.findIndex(
              y => y.title == data.title
            );
          }
          return childEditIndex == 0;
        } else if (node.level == 3) {
          this.data.forEach(e => {
            if (e && e.children && e.children.length) {
              e.children.forEach(f => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                }
              });
            }
          });
          let currentIndex = this.data[this.parentIndex].children.findIndex(
            x => x.title == node.parent.data.title
          );
          let subChildIndex = -1;
          if (currentIndex > -1) {
            subChildIndex = this.data[this.parentIndex].children[
              currentIndex
            ].children.findIndex(y => y.title == data.title);
          }
          return subChildIndex == 0;
        }
      } else if (data.type === "SubParent") {
        let currentIndex = this.data.findIndex(
          x => x.title == node.parent.data.title
        );
        let childEditIndex = -1;
        if (currentIndex > -1) {
          childEditIndex = this.data[currentIndex].children.findIndex(
            y => y.title == data.title
          );
        }
        return childEditIndex == 0;
      }

      return false;
    },
    checkIsLastNode(data, node = {}) {
      if (data.type == "Individual" || data.type == "Parent") {
        let currentIndex = this.data.findIndex(x => x.title == data.title);
        return currentIndex == this.data.length - 1;
      } else if (data.type === "Child") {
        if (node.level == 2) {
          let currentIndex = this.data.findIndex(
            x => x.title == node.parent.data.title
          );
          let childEditIndex = -1;
          if (currentIndex > -1) {
            childEditIndex = this.data[currentIndex].children.findIndex(
              y => y.title == data.title
            );
          }
          return (
            this.data[currentIndex] &&
            this.data[currentIndex].children &&
            childEditIndex == this.data[currentIndex].children.length - 1
          );
        } else if (node.level == 3) {
          this.data.forEach(e => {
            if (e && e.children && e.children.length) {
              e.children.forEach(f => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                }
              });
            }
          });
          let currentIndex = this.data[this.parentIndex].children.findIndex(
            x => x.title == node.parent.data.title
          );
          let subChildIndex = -1;
          if (currentIndex > -1) {
            subChildIndex = this.data[this.parentIndex].children[
              currentIndex
            ].children.findIndex(y => y.title == data.title);
          }
          return (
            this.data[this.parentIndex].children[currentIndex] &&
            this.data[this.parentIndex].children[currentIndex].children &&
            subChildIndex ==
              this.data[this.parentIndex].children[currentIndex].children
                .length -
                1
          );
        }
      } else if (data.type === "SubParent") {
        let currentIndex = this.data.findIndex(
          x => x.title == node.parent.data.title
        );
        let childEditIndex = -1;
        if (currentIndex > -1) {
          childEditIndex = this.data[currentIndex].children.findIndex(
            y => y.title == data.title
          );
        }
        return (
          this.data[currentIndex] &&
          this.data[currentIndex].children &&
          childEditIndex == this.data[currentIndex].children.length - 1
        );
      }
      return false;
    },
    async editMenuItem(data, node = {}) {
      try {
        if (data && data.feature == "entities") {
          await this.getFeatures(
            data.nested_relational_entity &&
              data.nested_relational_entity.includes("#")
              ? data.nested_relational_entity.split("#")[1]
              : data.relational_entity
              ? data.relational_entity
              : data.entity_id && data.entity_id._id
              ? data.entity_id._id
              : data.entity_id
          );
        }
        if (node.level == 1) {
          this.editIndex = this.data.findIndex(x => x.title == data.title);
          let type =
            this.data[this.editIndex]?.children &&
            this.data[this.editIndex].children.length
              ? "Parent"
              : this.data[this.editIndex].type == "Child"
              ? "Individual"
              : "Individual";
          this.form = { ...this.data[this.editIndex], ...{ type: type } };
        } else if (node.level == 2) {
          this.parentIndex = this.data.findIndex(
            x => x.title == node.parent.data.title
          );
          this.childEditIndex = this.data[this.parentIndex].children.findIndex(
            y => y.title == data.title
          );
          if (node.parent.data.children[this.childEditIndex].type == "Child") {
            this.form = {
              parent: node.parent.data.title,
              ...this.data[this.parentIndex].children[this.childEditIndex],
              ...{ type: "Child", parent: this.data[this.parentIndex].title }
            };
          } else if (
            node.parent.data.children[this.childEditIndex].type == "SubParent"
          ) {
            this.form = {
              parent: node.parent.data.title,
              ...this.data[this.parentIndex].children[this.childEditIndex],
              ...{
                type: "SubParent",
                parent: this.data[this.parentIndex].title
              }
            };
          }
        } else if (node.level == 3) {
          this.data.forEach(e => {
            if (e && e.children && e.children.length) {
              e.children.forEach(f => {
                if (f.title === node.parent.data.title) {
                  this.subParentIndex = e.children.indexOf(f);
                  this.parentIndex = this.data.indexOf(e);
                  this.subChildIndex = node.parent.data.children.findIndex(
                    e => e.title === node.data.title
                  );
                }
              });
            }
          });
          this.form = {
            parent: node.parent.data.title,
            ...this.data[this.parentIndex].children[this.subParentIndex]
              .children[this.subChildIndex],
            ...{
              type: "Child",
              parent: this.data[this.parentIndex].children[this.subParentIndex]
                .title
            }
          };
        }
        if (!this.form["self_related_filter"])
          this.form["self_related_filter"] = "login_user";
      } catch (err) {
        console.log("editMenuItem", err);
        this.errorConfirm();
      }
    },
    async deleteMenuItem(data, node = {}) {
      try {
        if (data.isChecked) {
          this.$message.error(
            "This item is used as the main route and cannot be deleted."
          );
          return;
        }
        this.refresh = true;
        if (node.level == 1) {
          let index;
          index = this.data.findIndex(
            x => JSON.stringify(x) === JSON.stringify(data)
          );
          if (index > -1) {
            this.data.splice(index, 1);
          }
        }

        if (node.level == 2) {
          let parentIndex = this.data.findIndex(
            x => x.title === node.parent.data.title
          );
          let childIndex = this.data[parentIndex].children.findIndex(
            item => item.title === data.title
          );
          this.data[parentIndex].children.splice(childIndex, 1);
        }
        if (node.level == 3) {
          this.data.forEach((parent, i) => {
            if (
              parent &&
              parent.children &&
              parent.children.length &&
              (this.ParentIndex == undefined || this.ParentIndex == -1)
            ) {
              this.ParentIndex = parent.children.findIndex(
                x => x.title == node.parent.data.title
              );
              if (this.ParentIndex !== -1) {
                this.childEditIndex = this.data[i].children[
                  this.ParentIndex
                ].children.findIndex(item => item.title === data.title);
                this.data[i].children[this.ParentIndex].children.splice(
                  this.childEditIndex,
                  1
                );
              }
            }
          });
          this.ParentIndex = -1;
        }

        this.refresh = false;
      } catch (err) {
        console.log("deleteMenuItem", err);
        this.refresh = false;
        this.errorConfirm();
      }
    },
    errorConfirm() {
      this.$confirm(
        "Menu is not configured properly. Click on continue to delete menu and add new one.",
        "Warning",
        {
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(() => {
        // this.deleteMenu();
      });
    },
    moveToTop(data, node = {}) {
      if (data) {
        this.refresh = true;
        if (data.type == "Individual" || data.type == "Parent") {
          let currentIndex = this.data.findIndex(x => x.title == data.title);
          if (currentIndex > 0) {
            let temp = this.data[currentIndex];
            this.data[currentIndex] = this.data[currentIndex - 1];
            this.data[currentIndex - 1] = temp;
          }
        } else if (data.type === "Child") {
          if (node.level == 2) {
            let currentIndex = this.data.findIndex(
              x => x.title == node.parent.data.title
            );
            let childEditIndex = this.data[currentIndex].children.findIndex(
              y => y.title == data.title
            );
            if (childEditIndex > 0) {
              let tempParent = this.data[currentIndex];
              let temp = tempParent.children[childEditIndex];
              tempParent.children[childEditIndex] =
                tempParent.children[childEditIndex - 1];
              tempParent.children[childEditIndex - 1] = temp;
              this.data[currentIndex] = tempParent;
            }
          } else if (node.level == 3) {
            this.data.forEach(e => {
              if (e && e.children && e.children.length) {
                e.children.forEach(f => {
                  if (f.title === node.parent.data.title) {
                    this.subParentIndex = e.children.indexOf(f);
                    this.parentIndex = this.data.indexOf(e);
                  }
                });
              }
            });
            let currentIndex = this.data[this.parentIndex].children.findIndex(
              x => x.title == node.parent.data.title
            );

            let subParentIndex = this.data[this.parentIndex].children[
              currentIndex
            ].children.findIndex(y => y.title == data.title);

            if (subParentIndex > 0) {
              let tempParent = this.data[this.parentIndex].children[
                currentIndex
              ];
              let temp = tempParent.children[subParentIndex];
              tempParent.children[subParentIndex] =
                tempParent.children[subParentIndex - 1];
              tempParent.children[subParentIndex - 1] = temp;
              this.data[this.parentIndex].children[currentIndex] = tempParent;
            }
          }
        } else if (data.type === "SubParent") {
          let currentIndex = this.data.findIndex(
            x => x.title == node.parent.data.title
          );
          let childEditIndex = this.data[currentIndex].children.findIndex(
            y => y.title == data.title
          );
          if (childEditIndex > 0) {
            let tempParent = this.data[currentIndex];
            let temp = tempParent.children[childEditIndex];
            tempParent.children[childEditIndex] =
              tempParent.children[childEditIndex - 1];
            tempParent.children[childEditIndex - 1] = temp;
            this.data[currentIndex] = tempParent;
          }
        }
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    moveToBottom(data, node = {}) {
      if (data) {
        this.refresh = true;
        if (data.type == "Individual" || data.type == "Parent") {
          let currentIndex = this.data.findIndex(x => x.title == data.title);
          if (currentIndex < this.data.length - 1) {
            let temp = this.data[currentIndex];
            this.data[currentIndex] = this.data[currentIndex + 1];
            this.data[currentIndex + 1] = temp;
          }
        } else if (data.type === "Child") {
          if (node.level == 2) {
            let currentIndex = this.data.findIndex(
              x => x.title == node.parent.data.title
            );
            let childEditIndex = this.data[currentIndex].children.findIndex(
              y => y.title == data.title
            );
            if (
              this.data[currentIndex].children &&
              childEditIndex < this.data[currentIndex].children.length - 1
            ) {
              let tempParent = this.data[currentIndex];
              let temp = tempParent.children[childEditIndex];
              tempParent.children[childEditIndex] =
                tempParent.children[childEditIndex + 1];
              tempParent.children[childEditIndex + 1] = temp;
              this.data[currentIndex] = tempParent;
            }
          } else if (node.level == 3) {
            this.data.forEach(e => {
              if (e && e.children && e.children.length) {
                e.children.forEach(f => {
                  if (f.title === node.parent.data.title) {
                    this.subParentIndex = e.children.indexOf(f);
                    this.parentIndex = this.data.indexOf(e);
                  }
                });
              }
            });
            let currentIndex = this.data[this.parentIndex].children.findIndex(
              x => x.title == node.parent.data.title
            );

            let subParentIndex = this.data[this.parentIndex].children[
              currentIndex
            ].children.findIndex(y => y.title == data.title);
            if (
              this.data[this.parentIndex].children[currentIndex].children &&
              subParentIndex <
                this.data[this.parentIndex].children[currentIndex].children
                  .length -
                  1
            ) {
              let tempParent = this.data[this.parentIndex].children[
                currentIndex
              ];
              let temp = tempParent.children[subParentIndex];
              tempParent.children[subParentIndex] =
                tempParent.children[subParentIndex + 1];
              tempParent.children[subParentIndex + 1] = temp;
              this.data[this.parentIndex].children[currentIndex] = tempParent;
            }
          }
        } else if (data.type === "SubParent") {
          let currentIndex = this.data.findIndex(
            x => x.title == node.parent.data.title
          );
          let childEditIndex = this.data[currentIndex].children.findIndex(
            y => y.title == data.title
          );
          if (
            this.data[currentIndex].children &&
            childEditIndex < this.data[currentIndex].children.length - 1
          ) {
            let tempParent = this.data[currentIndex];
            let temp = tempParent.children[childEditIndex];
            tempParent.children[childEditIndex] =
              tempParent.children[childEditIndex + 1];
            tempParent.children[childEditIndex + 1] = temp;
            this.data[currentIndex] = tempParent;
          }
        }
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    setIconPath(icon) {
      this.form.icon = icon.url;
      this.form.has_dark_icons = true;
      this.iconPickerdialogVisible = false;
    },
    setIcon(icon) {
      this.form.icon = icon.url;
      this.form.icon_category = this.selectedCategory;
      this.form.has_dark_icons = false;
      this.iconPickerdialogVisible = false;
    },
    async getFeatures(entity_id) {
      const entityData = await fetchEntityById(entity_id, false);
      this.form.entity_view_type = entityData.viewType || "TABLE";
      await Promise.all([
        this.getEntityFilters(entity_id),
        this.getEntityCustomizations(entity_id)
      ]);
    },
    async getCurrentEntityFilters(entity_id) {
      this.loading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id
      });
      if (this.getAllEntityFilters) {
        this.loading = false;
        return this.getAllEntityFilters;
      } else {
        this.loading = false;
        return [];
      }
    },
    async getEntityFilters(entity_id) {
      this.loading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id
      });
      this.entityAllFilters = [];
      if (this.form.filters) {
        this.form.filters = "";
      }
      if (this.getAllEntityFilters) {
        this.entityAllFilters = this.getAllEntityFilters;
      }
      this.loading = false;
    },
    async getEntityCustomizations(entity_id) {
      this.loading = true;
      await this.$store.dispatch(
        "entityCustomization/fetchAllEntityCustomizations",
        entity_id
      );
      this.entityAllCustomizations = [];
      if (this.getAllCustomizations) {
        this.entityAllCustomizations = this.getAllCustomizations.data;
      }
      this.form.customization = "";
      this.loading = false;
    },
    onMenuItemSubmit() {
      this.form.title = this.form.title.trim();
      let nameExists = false;
      if (!this.form.title) {
        this.$message.error("Please enter menu title");
        return;
      }
      let element = { ...this.form };
      let index = -1;
      if (element.type == "Child") {
        index = this.data.findIndex(x => x.title == element.parent);
      } else if (element.type == "SubParent") {
        index = this.data.findIndex(x => x.title == element.parent);
      } else if (this.editIndex > -1) {
        index = this.editIndex;
      } else {
        index = this.data.findIndex(x => x.title == element.parent);
      }
      this.data.forEach((item, j) => {
        if (
          item.title &&
          j != index &&
          this.form.type == item.type &&
          item.title.toLowerCase() == this.form.title.toLowerCase()
        ) {
          nameExists = true;
        } else if (item.children && item.children.length) {
          item.children.forEach((e, i) => {
            // if (
            //   e.title &&
            //   index == i &&
            //   e.title.toLowerCase() == this.form.title.toLowerCase()
            // ) {
            //   nameExists = true;
            // }
            console.log("i", i);
            if (e.children && e.children.length && e.type == "SubParent") {
              e.children.forEach((el, k) => {
                if (
                  el.title &&
                  index == k &&
                  el.title.toLowerCase() == this.form.title.toLowerCase()
                ) {
                  nameExists = true;
                }
              });
            }
          });
        }
      });
      if (nameExists) {
        this.$notify.error({
          title: "Error",
          message: "Title name already exists"
        });
        return;
      }
      this.refresh = true;
      if (element.type == "Child") {
        let parentIndex = this.data.findIndex(x => x.title == element.parent);
        element.children = [];
        if (parentIndex > -1) {
          let subParentIndex = this.data[parentIndex].children.findIndex(
            x => x.title == element.parent
          );
          if (this.childEditIndex > -1 && this.parentIndex == parentIndex) {
            this.data[parentIndex].children[this.childEditIndex] = element;
          } else if (
            this.childEditIndex > -1 &&
            this.parentIndex != parentIndex
          ) {
            this.data[this.parentIndex].children.splice(this.childEditIndex, 1);
            this.data[parentIndex].children.push(element);
          } else if (
            this.subChildIndex > -1 &&
            this.subParentIndex != subParentIndex
          ) {
            this.data[this.parentIndex].children[
              this.subParentIndex
            ].children.splice(this.subChildIndex, 1);
            this.data[parentIndex].children.push(element);
          } else {
            this.data[parentIndex].children.push(element);
          }
        } else {
          this.data.forEach((parent, i) => {
            let subParentIndex = parent.children.findIndex(
              x => x.title == element.parent
            );
            element.children = [];
            if (subParentIndex > -1) {
              if (
                this.subChildIndex > -1 &&
                this.subParentIndex == subParentIndex
              ) {
                if (this.parentIndex != parentIndex) {
                  this.data[this.parentIndex].children[
                    this.subParentIndex
                  ].children.splice(this.subChildIndex, 1);
                  this.data[i].children[subParentIndex].children.push(element);
                } else {
                  this.data[i].children[subParentIndex].children[
                    this.subChildIndex
                  ] = element;
                }
              } else if (
                this.childEditIndex > -1 &&
                this.parentIndex != parentIndex
              ) {
                this.data[this.parentIndex].children.splice(
                  this.childEditIndex,
                  1
                );
                if (this.childEditIndex < subParentIndex) {
                  this.data[i].children[this.subParentIndex].children.push(
                    element
                  );
                } else {
                  this.data[i].children[subParentIndex].children.push(element);
                }
              } else if (
                this.subChildIndex > -1 &&
                this.subParentIndex != subParentIndex
              ) {
                this.data[this.parentIndex].children[
                  this.subParentIndex
                ].children.splice(this.subChildIndex, 1);
                this.data[i].children[subParentIndex].children.push(element);
              } else {
                this.data[i].children[subParentIndex].children.push(element);
              }
            }
          });
        }

        if (this.editIndex > -1) {
          this.data.splice(this.editIndex, 1);
        }
      } else if (element.type == "SubParent") {
        let parentIndex = this.data.findIndex(x => x.title == element.parent);
        let subParentIndex = this.data[parentIndex].children.findIndex(
          x => x.title == element.parent
        );
        element.children = [];
        if (parentIndex > -1) {
          if (this.childEditIndex > -1 && this.parentIndex == parentIndex) {
            this.data[parentIndex].children[this.childEditIndex] = element;
          } else if (
            this.childEditIndex > -1 &&
            this.parentIndex != parentIndex
          ) {
            this.data[this.parentIndex].children.splice(this.childEditIndex, 1);
            this.data[parentIndex].children.push(element);
          } else if (
            this.subChildIndex > -1 &&
            this.subParentIndex != subParentIndex
          ) {
            this.data[this.parentIndex].children[
              this.subParentIndex
            ].children.splice(this.subChildIndex, 1);
            this.data[parentIndex].children.push(element);
          } else {
            this.data[parentIndex].children.push(element);
          }
        }
        if (this.editIndex > -1) {
          this.data.splice(this.editIndex, 1);
        }
      } else if (element.type == "Parent") {
        if (this.editIndex > -1) {
          this.data[this.editIndex] = element;
        } else {
          if (this.childEditIndex > -1 && element.parent) {
            let parentIndex = this.data.findIndex(
              x => x.title == element.parent
            );
            this.data[parentIndex].children.splice(this.childEditIndex, 1);
            element.parent = "";
          } else if (this.subChildIndex > -1 && element.parent) {
            let subParentIndex = this.data[this.parentIndex].children.findIndex(
              x => x.title == element.parent
            );
            this.data[this.parentIndex].children[
              subParentIndex
            ].children.splice(this.subChildIndex, 1);
            element.parent = "";
          }
          this.data.push(element);
        }
      } else {
        if (this.editIndex > -1) {
          this.data[this.editIndex] = element;
        } else {
          if (this.childEditIndex > -1 && element.parent) {
            let parentIndex = this.data.findIndex(
              x => x.title == element.parent
            );
            this.data[parentIndex].children.splice(this.childEditIndex, 1);
            element.parent = "";
          } else if (this.subChildIndex > -1 && element.parent) {
            let subParentIndex = this.data[this.parentIndex].children.findIndex(
              x => x.title == element.parent
            );
            this.data[this.parentIndex].children[
              subParentIndex
            ].children.splice(this.subChildIndex, 1);
            element.parent = "";
          }

          this.data.push(element);
        }
      }
      this.editIndex = -1;
      this.childEditIndex = -1;
      (this.subParentEditIndex = -1), (this.subChildIndex = -1);
      this.form = {
        title: "",
        feature: "",
        category: "",
        type: "Individual",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        isGroup: "",
        groups: "",
        relational_entity: "",
        entity_type: "ENTITY",
        redirectMenuItem: "",
        entity_view_type: "",
      };
      setTimeout(() => {
        this.refresh = false;
      });
    },
    setIconBgColor() {
      if (this.getCompanyDetails && this.getCompanyDetails?.button) {
        this.iconBgColor = this.getCompanyDetails?.button?.background;
      }
    }
    // searchByname(event) {
    //   if (this.searchIcon) {
    //     const searchQuery = event.toLowerCase();
    //     if (this.theme === "Dark Icons") {
    //       this.searchIconResult = this.menuIcons.filter((el) =>
    //         el.name.toLowerCase().includes(searchQuery)
    //       );
    //     } else {
    //       this.searchIconResult = this.menuIconsWhite.filter((el) =>
    //         el.name.toLowerCase().includes(searchQuery)
    //       );
    //     }
    //   }
    // },
  }
};
</script>
<style lang="scss">
.sidemenu {
  height: 420px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

//   .sidemenu::-webkit-scrollbar-button:single-button {
//       background-color: #bbbbbb;
//       display: block;
//       border-style: solid;
//     //  height: 13px;
//     //  width: 16px;
//     }
// .sidemenu::-webkit-scrollbar-button:single-button:vertical:decrement {
//   border-width: 0 8px 8px 8px;
//   border-color: transparent transparent #555555 transparent;
// }

// .sidemenu::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
//   border-color: transparent transparent #777777 transparent;
// }
// /* Down */
// .sidemenu::-webkit-scrollbar-button:single-button:vertical:increment {
//    border-width: 8px 8px 0 8px;
//   border-color: #555555 transparent transparent transparent;
// }

// .sidemenu::-webkit-scrollbar-button:single-button:vertical :increment:hover {
//   border-color: #777777 transparent transparent transparent;
// }
.user-menu-container {
  background: #f5f7ff;
  border: 1px solid rgba(168, 171, 255, 0.3);
  border-radius: 20px;
  padding: 2em;
  position: relative;

  .menuPreviewButton {
    position: absolute;
    top: -1.5em;
    right: 15em;
    border-radius: 5px;
  }

  .menuSubmitButton {
    position: absolute;
    top: -1.5em;
    right: 2em;
    border-radius: 5px;
  }

  .el-tree {
    background-color: transparent;

    .el-tree-node__content {
      height: 6em;

      &:hover {
        background-color: #f7f9fd;
      }
    }
  }

  .each-item {
    width: 25em;
    overflow: visible;
    margin-bottom: 1em;
    min-height: 5em;
    position: relative;
    z-index: 20;

    .el-card__body {
      padding-top: 0.75em;
    }

    &:last-child {
      margin-bottom: 0em;
    }

    & + .sub-item-1 {
      position: relative;
      margin-left: 3.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -2em;
        width: 2em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -2em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }

    & + .sub-item-2 {
      position: relative;
      margin-left: 5.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -4em;
        width: 4em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -4em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }
  }
}

.template-icon {
  width: 30px;
  height: 20px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  text-align: center;
}

.border-radius {
  border-radius: 4px;
}

.circle-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  //line-height: 100px;
  //vertical-align: middle;
  margin-top: 10px;
}
.circle-icon-2 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  // margin-top: 5px;
}
.actions-btn {
  margin-top: -25px !important;
}
</style>
<style lang="scss" scoped>
.bt .h2 {
  font-size: 1.5em;
}
.bt .h4 {
  font-size: 1.2em;
}
</style>
