<template>
  <section
    class="top-navbar"
    :style="`background: ${loginPageColor} !important;`"
  >
    <div>
      <el-row type="flex" align="middle">
        <el-col :span="4">
          <div class="top-navbar-left-menu">
            <ul v-if="getIsMobile">
              <li>
                <div class="toggle-menu-animation">
                  <div
                    id="burger"
                    :class="isOpen ? 'active' : ''"
                    @click="sendMessageToParent"
                  >
                    <button type="button" class="burger-button" title="Menu">
                      <span class="hidden">Toggle menu</span>
                      <span class="burger-bar burger-bar--1"></span>
                      <span class="burger-bar burger-bar--2"></span>
                      <span class="burger-bar burger-bar--3"></span>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div class="company-logo">
                  <a href="/ap/dashboard">
                    <img
                      v-if="this.companyDataLocal.logo"
                      :src="this.companyDataLocal.logo"
                      alt="Nimble logo"
                      style="max-height: 34px; max-width: 150px"
                    />
                  </a>
                </div>
              </li>
            </ul>
            <ul v-else>
              <li>
                <div class="company-logo">
                  <a href="/ap/dashboard">
                    <img
                      v-if="this.companyDataLocal.logo"
                      :src="this.companyDataLocal.logo"
                      alt="Nimble logo"
                      style="max-height: 34px; max-width: 150px"
                    />
                  </a>
                </div>
              </li>
              <li>
                <div class="toggle-menu-animation">
                  <div
                    id="burger"
                    :class="isOpen ? 'active' : ''"
                    @click="sendMessageToParent"
                  >
                    <button type="button" class="burger-button" title="Menu">
                      <span class="hidden">Toggle menu</span>
                      <span class="burger-bar burger-bar--1"></span>
                      <span class="burger-bar burger-bar--2"></span>
                      <span class="burger-bar burger-bar--3"></span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="top-navbar-right-menu">
            <ul class="logout-dropdown">
              <li :class="getIsMobile ? 'workspace' : 'workspace mr-1'">
                <el-select
                  v-model="activeWorkspace"
                  placeholder="switch Workspace"
                  @change="changeWorkspaceRole"
                  :size="getIsMobile ? 'mini' : ''"
                  :style="getIsMobile ? 'float: right; width: 50%' : ''"
                >
                  <el-option
                    v-for="contact in getLoginContactTypes"
                    :key="contact._id"
                    :label="getName(contact.contact_type)"
                    :value="contact.contact_type._id"
                  ></el-option>
                </el-select>
              </li>
              <li>
                <!-- <div class="el-icon-user-solid"></div> -->
                <el-dropdown>
                  <el-button>
                    <div class="icon-block">
                      <div class="icon user-image profile-image">
                        <img
                          v-if="
                            getAuthenticatedUser &&
                            getAuthenticatedUser.avatar &&
                            getAuthenticatedUser.avatar.length > 20
                          "
                          :src="getAuthenticatedUser.avatar"
                          alt="user-image"
                          class="img-fluid"
                        />

                        <img
                          v-else
                          src="@/assets/img/avatar.svg"
                          alt="user-image"
                          class="img-fluid"
                        />
                      </div>
                      <div class="icon-text" v-if="!getIsMobile">
                        {{ getAuthenticatedUser | getUserFullName }}
                        <span class="el-icon-arrow-down text-white"></span>
                      </div>
                      <div class="icon-text" v-else>
                        {{ getAuthenticatedUser | getUserFullNameShortened }}
                        <span class="el-icon-arrow-down text-white"></span>
                      </div>
                    </div>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" style="width: 125px">
                    <a @click="openProfile"
                      ><el-dropdown-item>Profile</el-dropdown-item></a
                    >
                    <a @click="logoutUser"
                      ><el-dropdown-item>Logout</el-dropdown-item></a
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import config from "../../config/app";
import UserHelper from "@/mixins/UserHelper";
import chroma from "chroma-js";
import { clearDB } from "@/repo/appSettingRepo";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "ApplicationUserTopbar",
  data() {
    return {
      isOpen: "",
      isCollapse: "",
      loading: false,
      isExist: false,
      selectLoad: false,
      loginPageColor: "#1B487E",
      companyDataLocal: {},
      contactTypes: {},
      selectedContactType: "",
      activeWorkspace: "",
      botId: "",
      token: "",
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("company", ["getContactLoginSlug"]),
    getLoginContactTypes() {
      if (this.getAuthenticatedUser?.contact_types) {
        return this.getAuthenticatedUser.contact_types.filter(
          (e) => e.has_login
        );
      }
      return [];
    },
    fetchAllContactTypes() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.contact_types &&
        this.getAuthenticatedUser.contact_types.length
      ) {
        return [...this.getAuthenticatedUser.contact_types];
      }
      return [];
    },
  },
  mixins: [UserHelper],
  async mounted() {
    await this.getBotAccounts();
    if (this.getContactLoginSlug && this.getContactLoginSlug._id) {
      this.companyDataLocal = { ...this.getContactLoginSlug };
      if (this.getContactLoginSlug && this.getContactLoginSlug.logo) {
        this.companyDataLocal.logo = `${config.S3_BUCKET_URL}/${this.getContactLoginSlug.logo}`;
      }
      if (this.companyDataLocal?.button?.background) {
        this.loginPageColor = this.companyDataLocal.menubackground;
        document.documentElement.style.setProperty(
          "--primary-color",
          this.companyDataLocal.dialog_header_color
        );
        document.documentElement.style.setProperty(
          "--primary-contrast-color",
          this.companyDataLocal.dialog_header_text_color
        );
        document.documentElement.style.setProperty(
          "--sidemenu-background-color",
          this.companyDataLocal.sidemenu_background
        );
        document.documentElement.style.setProperty(
          "--sidemenu-text-color",
          this.companyDataLocal.sidemenu_text
        );
        document.documentElement.style.setProperty(
        "--icon-color",
        this.companyDataLocal.menu_icon_color
      );
      document.documentElement.style.setProperty(
        "--icon-hover-color",
        this.companyDataLocal.menu_icon_hover_color
      );
      document.documentElement.style.setProperty(
        "--tabs-color",
        this.companyDataLocal.tabs_color
      );
      document.documentElement.style.setProperty(
        "--tabs-hover-color",
        this.companyDataLocal.tabs_hover_color
      );
        const primaryColor = getComputedStyle(document.documentElement)
          .getPropertyValue("--primary-color")
          .trim();
        const lighterPrimaryColor = chroma(primaryColor).brighten(0.5).hex();
        const lighterbackgroundColor = chroma(primaryColor).brighten(1.0).hex();
        const darkerPrimaryColor = chroma(primaryColor).darken(0.5).hex();
        document.documentElement.style.setProperty(
          "--darker-primary-color",
          darkerPrimaryColor
        );
        document.documentElement.style.setProperty(
          "--lighter-primary-color",
          lighterPrimaryColor
        );
        document.documentElement.style.setProperty(
          "--lighter-background",
          lighterbackgroundColor
        );
        // document.documentElement.style.setProperty('--Hover-color-inputfield', this.companyInfo.menubackground);
        // document.documentElement.style.setProperty('--text-secondary-color', 'white');

        const secondaryColor = getComputedStyle(document.documentElement)
          .getPropertyValue("--secondary-color")
          .trim();

        const darkerSecondaryColor = chroma(secondaryColor).brighten(0.3).hex();

        document.documentElement.style.setProperty(
          "--darker-secondary-color",
          darkerSecondaryColor
        );
      }
    }
    this.setActiveContactType();
  },
  methods: {
    async getBotAccounts() {
      this.loading = true;
      this.loadingText = "Loading Chat Bots ...";
      try {
        let response = await postAPICall("GET", "/bot/get-Bot");

        if (response && response.success) {
          this.botAccounts = response.data?.chatBotDetails || [];

          const botIdRegex = /botId: '(.*?)'/;
          const tokenRegex = /token: '(.*?)'/;

          // Extract botId and token values
          this.botId = this.botAccounts.match(botIdRegex)[1];
          this.token = this.botAccounts.match(tokenRegex)[1];
          localStorage.setItem("botId", this.botId);
          localStorage.setItem("token", this.token);
          const storedBotId = localStorage.getItem("botId");
          const storedToken = localStorage.getItem("token");

          if (storedBotId && storedToken) {
            window.bus.$emit("chatbot");
          } else {
            console.error("Failed to store botId or token in localStorage.");
          }
        } else {
          // this.$message.error("Failed to fetch Chat Bots: " + response.message);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getName(contact_type) {
      return contact_type.receiver_alias || contact_type.name;
    },
    setActiveContactType() {
      if (this.getActiveContactType?.contact_type?._id) {
        this.activeWorkspace = this.getActiveContactType.contact_type._id;
      }
    },
    async changeWorkspaceRole() {
      Swal.close();
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure to switch the application?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let selectedWorkspace = (
            this.getAuthenticatedUser.contact_types || []
          ).find((e) => e?.contact_type?._id == this.activeWorkspace);
          if (selectedWorkspace) {
            await this.$store.commit(
              "auth/setActiveContactType",
              selectedWorkspace,
              {
                root: true,
              }
            );
            let user = this.getAuthenticatedUser;
            user["account_data_id"] = selectedWorkspace.account_data_id;
            user["contact_type"] = selectedWorkspace.contact_type
              ? selectedWorkspace.contact_type._id
                ? selectedWorkspace.contact_type._id
                : selectedWorkspace.contact_type
              : "";
            await this.$store.commit("auth/setAuthenticatedUser", user, {
              root: true,
            });

            await this.checkAndUpdateData();
          } else {
            this.resetWorkspace();
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.resetWorkspace();
        }
      });
    },
    resetWorkspace() {
      let selectedWorkspace = (
        this.getAuthenticatedUser.contact_types || []
      ).find(
        (e) =>
          e?.contact_type?._id == this.getActiveContactType?.contact_type?._id
      );
      if (selectedWorkspace) {
        this.activeWorkspace = selectedWorkspace?.contact_type?._id;
      } else {
        this.handleLogoutInfo();
      }
    },
    async handleLogoutInfo() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Due to inactivity for a prolonged period, your session has been automatically logged out for security purposes. Please feel free to log in again when you're ready!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: "",
            html: "Please wait ..... ",
            timer: 2000,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
              this.logoutUser();
            },
          });
        }
      });
    },
    sendMessageToParent() {
      this.isOpen = !this.isOpen;
      this.$store.commit("navigationOpen/setIsOpen", !this.getIsOpen, {
        root: true,
      });
      this.$emit("open:sidebar", this.isOpen);
    },
    logoutUser() {
      localStorage.removeItem("botId");
      localStorage.removeItem("token");
      window.bus.$emit("chatbot");
      Swal.fire({
        title: "",
        html: "signing off....",
        timer: 3000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
          localStorage.removeItem(config.APP_NAME + "_" + config.APP_VERSION);
          localStorage.clear();
          this.resetStore();
          clearDB(true);
        },
        willClose: () => {
          if (this.companyDataLocal?.slug) {
            this.$router.push(
              `/ap/signin?company=${this.companyDataLocal.slug}`
            );
          } else {
            this.$router.push("/signin");
          }
        },
      });
      this.resetStore();
    },
    resetStore() {
      this.$store.commit("auth/resetAuthData");
      this.$store.commit("profile/resetProfileData");
      this.$store.commit("company/resetCompanyData");
      this.$store.commit("auth/resetAuthData", null, { root: true });
      this.$store.commit("company/resetCompanyData", null, { root: true });
      this.$store.commit("companyDocuments/resetCompanyDocumentsData", null, {
        root: true,
      });
      this.$store.commit("documentGroups/resetDocumentGroupsData", null, {
        root: true,
      });
      this.$store.commit(
        "documentCategories/resetDocumentCategoriesData",
        null,
        {
          root: true,
        }
      );

      this.$store.commit("companyBenefits/resetCompanyBenefitsData", null, {
        root: true,
      });
      this.$store.commit("companyTemplates/resetCompanyTemplatesData", null, {
        root: true,
      });
      this.$store.commit("offerLetter/resetOfferLetterData", null, {
        root: true,
      });
      this.$store.commit("employee/resetEmployeesData", null, {
        root: true,
      });
      this.$store.commit("configurableDocuments/resetConfigurableDocuments");

      this.$store.commit("standardDocumentConfig/reset");
      this.$store.commit("employeeData/reset");
      this.$store.commit("employeeDocuments/reset");
      this.$store.commit("workflowData/reset");
      this.$store.commit("workflows/reset");
      this.$store.commit("templatesData/reset");
      this.$store.commit("i9formDocuments/reset");
      this.$store.commit("authorizedRepresentatives/reset");
      this.$store.commit("employeeDocumentAuthorizedUsers/reset");
    },
    openProfile() {
      this.$router.push("/ap/profile");
    },
  },
};
</script>

<style lang="scss">
.top-navbar {
  position: fixed;
  height: 6vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #1b487e;
  color: #ffffff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  .top-navbar-left-menu {
    height: 6vh;
    display: flex;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      li {
        &:not(:last-child) {
          margin-right: 25px;
        }
        .company-logo {
          padding-top: 15px;
          padding-bottom: 10px;
          img {
            width: 100px;
          }
        }
      }
    }
  }
  .top-navbar-right-menu {
    .logout-dropdown {
      float: right;
      position: relative;
      display: flex;
      align-items: center;
      .alert-icon {
        position: relative;
        margin-right: 35px;
        .alert-count {
          position: absolute;
          top: -12px;
          right: -6px;
          span {
            background: #f754a2;
            font-size: 0.8em;
            border: 1px solid #f754a2;
            color: #ffffff;
            padding: 2px;
            width: 9px;
            height: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }
        img {
          width: 22px;
          margin-top: 5px;
        }
      }

      .top-search {
        .el-input__inner {
          border-radius: 3px;
          background: rgba(236, 245, 255, 0.1);
          font-size: 0.9rem;
          line-height: 2;
          width: calc(100% - 22px);
          border-color: rgba(236, 245, 255, 0.2);
          height: 38px;
          color: #ffffff;
          &:focus {
            border-color: #1b487e;
          }
        }
        .el-input__icon {
          line-height: 27px !important;
        }
      }

      .el-dropdown {
        font-size: 15px;
        padding-right: 0;
        display: flex;
        align-items: center;
        button {
          padding: 0;
          margin: 0;
          background: transparent;
          border: none;
        }
        &:hover {
          cursor: pointer;
        }
        .user-image {
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 10px;
            // margin-bottom: -20px;
          }
        }
        .icon-block {
          display: flex;
          align-items: center;
        }

        .icon-text {
          font-size: 1.1em;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: rgba($color: #ffffff, $alpha: 0.85);
          &:hover {
            color: rgba($color: #ffffff, $alpha: 1);
          }
          .dropdown-arrow {
            img {
              width: 10px;
            }
          }
        }

        .user-profile-image {
          img {
            width: 25px;
          }
        }
      }
    }
  }
  .toggle-menu-animation {
    .hidden {
      visibility: hidden;
    }

    /** burger **/

    button {
      cursor: pointer;
    }

    /* remove blue outline */
    button:focus {
      outline: 0;
    }

    .burger-button {
      position: relative;
      height: 30px;
      width: 40px;
      display: block;
      z-index: 60;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      pointer-events: all;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burger-bar {
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 6px;
      left: 6px;
      height: 2px;
      width: auto;
      margin-top: -1px;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      opacity: 1;
    }

    .burger-bar--1 {
      -webkit-transform: translateY(-6px);
      transform: translateY(-8px);
    }

    .burger-bar--3 {
      transform: translateY(8px);
    }

    #burger.active .burger-button {
      transform: rotate(-180deg);
    }

    #burger.active .burger-bar--1 {
      transform: rotate(45deg);
    }

    #burger.active .burger-bar--2 {
      opacity: 0;
    }

    #burger.active .burger-bar--3 {
      transform: rotate(-45deg);
    }
  }
  .user-role {
    color: rgba($color: #ffffff, $alpha: 0.95);
    font-size: 0.8em;
    font-weight: 300;
  }
}
body.modal-open {
  height: 100vh;
  //overflow-y: hidden;
}
.top-navbar-right-menu {
  @media (max-width: 500px) {
    .user-image {
      &.profile-image {
        display: none;
      }
    }
  }
  .user-role {
    @media (max-width: 500px) {
      text-align: left;
      padding-left: 0px;
    }
    padding-left: 27px;
  }
}
.workspace {
  // margin-right: 30px;
}
</style>
