var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"element-loading-text":"Please wait, Generating Filter..."}},[_c('div',{staticClass:"filter-ai-container"},[[_vm._v("You can apply Filters on entity data by selecting entity field.")],_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.addFilter}},[_vm._v("Add Filter+")]),_c('br'),_c('div',{staticClass:"try-ai-container mt-1 mr-1 d-flex align-items-center"},[_c('span',{staticClass:"ai-icon",on:{"click":function($event){_vm.showAiInput = !_vm.showAiInput}}},[_c('div',{staticClass:"ai-icon-content"},[_c('icons',{staticStyle:{"color":"var(--primary-color) !important","fill":"var(--primary-color) !important"},attrs:{"iconName":'TryUsingAI'}}),_c('span',{staticClass:"ai-text"},[_vm._v("Try Using AI")])],1)])])],2),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onCreateFilter.apply(null, arguments)}}},[(_vm.showAiInput)?_c('div',{staticClass:"mt-2 mr-2"},[_c('label',[_vm._v("Enter your prompt to create Filter")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('el-input',{staticClass:"flex-grow-1",attrs:{"placeholder":"Enter your AI prompt...","size":"small","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onCreateFilter.apply(null, arguments)}},model:{value:(_vm.userPrompt),callback:function ($$v) {_vm.userPrompt=$$v},expression:"userPrompt"}}),_c('el-button',{staticClass:"rotate-icon",attrs:{"slot":"append","icon":"el-icon-s-promotion","size":"small","disabled":_vm.userPrompt.length === 0},on:{"click":_vm.onCreateFilter},slot:"append"})],1)]):_vm._e()]),(
        _vm.entityFiltersData &&
        _vm.entityFiltersData._id &&
        !this.$route.query.routeType
      )?_c('div',{staticStyle:{"display":"flex !important"}},[(_vm.entityFiltersData.filter_name)?_c('p',[_vm._v(" Selected Filter: "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.entityFiltersData.filter_name)+" ")])]):_vm._e(),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Delete filter","placement":"top-start"}},[_c('el-link',{staticClass:"mb-1",attrs:{"type":"danger","underline":false},on:{"click":_vm.deleteFilter}},[_c('i',{staticClass:"el-icon-delete"})])],1)],1):_vm._e(),(
        _vm.entityUserFiltersData &&
        _vm.entityUserFiltersData._id &&
        this.$route.query.routeType
      )?_c('div',{staticStyle:{"display":"flex !important"}},[(_vm.entityUserFiltersData.filter_name)?_c('p',[_vm._v(" Selected Filter: "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.entityUserFiltersData.filter_name)+" ")])]):_vm._e(),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Delete filter","placement":"top-start"}},[_c('el-link',{staticClass:"mb-1",attrs:{"type":"danger","underline":false},on:{"click":_vm.deleteFilter}},[_c('i',{staticClass:"el-icon-delete"})])],1)],1):_vm._e(),_c('el-divider'),(
        _vm.entityFiltersData &&
        _vm.entityFiltersData.filters &&
        _vm.entityFiltersData.filters.length &&
        !this.$route.query.routeType
      )?_c('div',{staticClass:"mt-2"},[_vm._v(" Query type "),_c('el-radio-group',{model:{value:(_vm.entityFiltersData.query_type),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "query_type", $$v)},expression:"entityFiltersData.query_type"}},[_c('el-radio',{attrs:{"label":"AND"}}),_c('el-radio',{attrs:{"label":"OR"}})],1)],1):(!this.$route.query.routeType)?_c('div',[_vm._v(" There is no filter selected. Please click on Add Filter. ")]):_vm._e(),_c('br'),_c('el-scrollbar',{ref:"filterScroll",attrs:{"wrap-style":"max-height: 250px;"}},[(_vm.entityFiltersData.filters || _vm.entityUserFiltersData.filters)?_c('table',_vm._l((_vm.filteredFilters),function(filter,index){return _c('tr',{key:index + '-' + filter.field},[_c('td',[_c('tr',[_c('td',[_c('el-tooltip',{attrs:{"effect":"dark","content":"Select entity field","placement":"top-start"}},[_c('el-select',{attrs:{"placeholder":"Entity Field","filterable":"","size":"mini"},on:{"change":(changedValue) =>
                        _vm.checkCurrentFieldData(changedValue, filter)},model:{value:(filter.field),callback:function ($$v) {_vm.$set(filter, "field", $$v)},expression:"filter.field"}},[_vm._l((_vm.selectedEntityFields.filter(
                        (e) => _vm.contentFields.indexOf(e.inputType) == -1
                      )),function(field,index){return _c('el-option',{key:index,attrs:{"value":field.key,"label":`${field.template_name} - ${field.label}`,"disabled":_vm.checkFilterExistedOrNot(field)}},[_vm._v(" "+_vm._s(field.template_name)+" - "+_vm._s(field.label)+" ")])}),_c('el-option',{attrs:{"value":"created_at"}},[_vm._v(" "+_vm._s(_vm.getButtonLabel("Created at", "Created Date")))]),_c('el-option',{attrs:{"value":"updated_at"}},[_vm._v(" "+_vm._s(_vm.getButtonLabel("Updated at", "Updated Date")))]),_c('el-option',{attrs:{"value":"created_by"}},[_vm._v(" "+_vm._s(_vm.getButtonLabel("Created by", "Created By")))]),_c('el-option',{attrs:{"value":"updated_by"}},[_vm._v(" "+_vm._s(_vm.getButtonLabel("Updated by", "Updated By")))]),(_vm.currentEntity.entity_type !== 'INDIVIDUAL')?_c('el-option',{attrs:{"value":"status","label":"Status"}}):_vm._e(),_c('el-option',{attrs:{"value":"isCreatedFromFormbuilder","label":"Is created from form builder?"}})],2)],1)],1),(filter.field !== 'isCreatedFromFormbuilder')?_c('td',[_c('el-tooltip',{attrs:{"effect":"dark","content":"Select operator","placement":"top-start"}},[_c('el-select',{attrs:{"size":"mini","disabled":!filter.field,"placeholder":"Operator"},model:{value:(filter.operator),callback:function ($$v) {_vm.$set(filter, "operator", $$v)},expression:"filter.operator"}},[_vm._l((_vm.getCurrentFieldOperators(
                        filter
                      )),function(operator,index){return _c('el-option',{key:index,attrs:{"label":operator.title,"value":operator.value}})}),(
                        filter.input_type == 'SELECT' &&
                        filter.data_type == 'ARRAY'
                      )?_c('el-option',{attrs:{"value":"=text-includes","label":"Text Includes"}}):_vm._e()],2)],1)],1):_vm._e(),(
                  filter.field == 'isCreatedFromFormbuilder' &&
                  filter.data_type == 'isCreatedFromFormbuilder'
                )?_c('td',[_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[_c('el-option',{attrs:{"value":true,"label":"Created from form builder"}}),_c('el-option',{attrs:{"value":false,"label":"Not created from form builder"}})],1)],1):_vm._e(),(
                  filter.field == 'status' && filter.data_type == 'STATUS'
                )?_c('td',[_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[_c('el-option',{attrs:{"value":"ACTIVE","label":"Active"}}),_c('el-option',{attrs:{"value":"ARCHIVED","label":"Deleted"}})],1)],1):_vm._e(),(
                  filter.operator != 'exists' &&
                  filter.operator != 'notexists' &&
                  filter.operator != 'between' &&
                  filter.operator != 'notbetween' &&
                  filter.operator != 'dateinclude' &&
                  filter.operator != 'notdateinclude' &&
                  filter.operator != 'real_time' &&
                  filter.operator != 'weekdays' &&
                  filter.operator != 'filter' &&
                  filter.data_type !== 'STATUS' &&
                  filter.data_type !== 'isCreatedFromFormbuilder'
                )?_c('td',[_c('el-tooltip',{attrs:{"effect":"dark","content":"Select value type","placement":"top-start"}},[(
                      filter.operator != 'lastmonth' &&
                      filter.operator != 'thismonth'
                    )?_c('el-select',{attrs:{"size":"mini","placeholder":"Value type","disabled":!filter.operator},on:{"change":(value) => _vm.checkSourceDataChange(value, filter)},model:{value:(filter.data_source),callback:function ($$v) {_vm.$set(filter, "data_source", $$v)},expression:"filter.data_source"}},[_vm._l((_vm.allowedDataSources(filter)),function(source,index){return _c('el-option',{key:index,attrs:{"label":source,"value":source}})}),(
                        _vm.checkTemplateCondition(filter) &&
                        filter.data_type != 'created_by' &&
                        filter.data_type != 'updated_by' &&
                        filter.data_type != 'created_at' &&
                        filter.data_type != 'updated_at' &&
                        filter.input_type != 'SELECT'
                      )?_c('el-option',{attrs:{"value":"TEMPLATE","label":"Template"}}):_vm._e(),(
                        filter.data_type == 'created_by' ||
                        filter.data_type == 'updated_by'
                      )?_c('el-option',{attrs:{"value":"LOGIN_USER","label":"Login User"}}):_vm._e(),(filter.data_type == 'WEEKDAYS')?_c('el-option',{attrs:{"value":"weekdays","label":"Weekdays"}}):_vm._e(),(_vm.checkIsSelect(filter))?_c('el-option',{attrs:{"value":"field_option","label":"From field"}}):_vm._e(),(_vm.checkSelfTemplateFields(filter))?_c('el-option',{attrs:{"value":"self_field","label":"Template"}}):_vm._e(),(
                        filter.data_type == 'STRING' &&
                        _vm.allowedLoginFilterOp.indexOf(filter.operator) != -1
                      )?_c('el-option',{attrs:{"value":"login_user","label":"FROM LOGIN USER"}}):_vm._e(),(
                        filter.input_type == 'SELECT' &&
                        filter.data_type == 'ARRAY' &&
                        filter.operator == '=text-includes'
                      )?_c('el-option',{attrs:{"value":"VALUE","label":"Value"}}):_vm._e()],2):_vm._e()],1)],1):_vm._e(),(
                  filter.operator != 'exists' &&
                  filter.operator != 'notexists'
                )?_c('td',[_c('div',[(filter.data_source == 'TEMPLATE')?_c('el-select',{attrs:{"size":"mini","placeholder":"Template fields"},model:{value:(filter.value_field),callback:function ($$v) {_vm.$set(filter, "value_field", $$v)},expression:"filter.value_field"}},_vm._l((_vm.getSelfTemplateFieldsByType(filter)),function(enityData,index){return _c('el-option',{key:index,attrs:{"label":enityData.label,"value":enityData.key}})}),1):_vm._e(),(
                      filter.data_type == 'BOOLEAN' &&
                      filter.data_source != 'TEMPLATE'
                    )?_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[_c('el-option',{attrs:{"value":true,"label":"Checked"}}),_c('el-option',{attrs:{"value":false,"label":"Unchecked"}})],1):_vm._e(),(
                      filter.data_type == 'DATE' &&
                      filter.data_source == 'CURRENT_DATE'
                    )?_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[_c('el-option',{attrs:{"value":"today","label":"Today"}})],1):_vm._e(),(
                      filter.data_type == 'YES_OR_NO' &&
                      filter.data_source != 'TEMPLATE'
                    )?_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[_c('el-option',{attrs:{"value":"YES","label":"Yes"}}),_c('el-option',{attrs:{"value":"NO","label":"No"}})],1):_vm._e(),(
                      filter.data_type == 'DATE' &&
                      filter.operator == 'weekdays'
                    )?_c('el-select',{attrs:{"size":"mini","multiple":"","collapse-tags":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.weekdays_options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.name}})}),1):_vm._e(),(
                      filter.data_source == 'weekdays' &&
                      filter.data_type == 'WEEKDAYS'
                    )?_c('el-select',{attrs:{"size":"mini","multiple":"","collapse-tags":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.weekdays_options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.key,"label":option.name}})}),1):_vm._e(),(
                      filter.data_type == 'RELATION' &&
                      filter.operator == 'filter'
                    )?_c('el-select',{attrs:{"disabled":!_vm.checkIsFirstRelationalField(index),"size":"mini"},on:{"change":_vm.setRelationalFilterQuery},model:{value:(filter.relational_filter_query),callback:function ($$v) {_vm.$set(filter, "relational_filter_query", $$v)},expression:"filter.relational_filter_query"}},_vm._l(([
                        { label: 'Includes', value: 'includes' },
                        { label: 'Not includes', value: 'not_includes' },
                      ]),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1):_vm._e(),(
                      filter.data_type == 'RELATION' &&
                      filter.operator == 'filter'
                    )?_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.getSelectedRelationalEntityFilters(filter)),function(source,index){return _c('el-option',{key:index,attrs:{"label":source.filter_name,"value":source._id}})}),1):_vm._e(),(filter.data_source == 'login_user')?_c('el-select',{attrs:{"size":"mini"},on:{"change":(changedValue) =>
                        _vm.checkCurrentFieldValue(changedValue, filter)},model:{value:(filter.value_field),callback:function ($$v) {_vm.$set(filter, "value_field", $$v)},expression:"filter.value_field"}},_vm._l((_vm.loginUserFields),function(op,index){return _c('el-option',{key:index + op.value,attrs:{"label":op.label,"value":op.value}},[_vm._v(_vm._s(op.label))])}),1):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      (filter.data_type == 'STRING' ||
                        (filter.input_type == 'SELECT' &&
                          filter.data_type == 'ARRAY' &&
                          filter.operator == '=text-includes'))
                    )?_c('el-input',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      (filter.data_type == 'NUMBER' ||
                        filter.data_type == 'CURRENCY') &&
                      filter.operator == 'between'
                    )?_c('div',[_c('el-input-number',{staticStyle:{"margin-right":"15px"},attrs:{"controls":false,"size":"mini","placeholder":"starting value","min":0},model:{value:(filter.range1),callback:function ($$v) {_vm.$set(filter, "range1", $$v)},expression:"filter.range1"}}),_c('span',[_vm._v("To")]),_c('el-input-number',{staticStyle:{"margin-left":"15px"},attrs:{"controls":false,"size":"mini","min":0,"placeholder":"ending value"},model:{value:(filter.range2),callback:function ($$v) {_vm.$set(filter, "range2", $$v)},expression:"filter.range2"}})],1):_vm._e(),(
                      filter.data_type == 'DATE' &&
                      (filter.operator == 'after' ||
                        filter.operator == 'before') &&
                      filter.data_source == 'VALUE'
                    )?_c('div',{staticClass:"counter-box"},[_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","min":1},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}),_c('el-select',{staticStyle:{"width":"100px","margin-left":"4px"},attrs:{"size":"mini"},model:{value:(filter.units),callback:function ($$v) {_vm.$set(filter, "units", $$v)},expression:"filter.units"}},[_c('el-option',{attrs:{"value":"days","label":"Day(s)"}}),_c('el-option',{attrs:{"value":"weeks","label":"Week(s)"}}),_c('el-option',{attrs:{"value":"months","label":"Month(s)"}}),_c('el-option',{attrs:{"value":"years","label":"Year(s)"}})],1)],1):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'NUMBER' &&
                      filter.operator != 'between'
                    )?_c('el-input-number',{attrs:{"controls":false,"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'CURRENCY'
                    )?_c('el-input-number',{attrs:{"controls":false,"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'DATE' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'between' &&
                      filter.operator != 'weekdays' &&
                      filter.operator != 'after' &&
                      filter.operator != 'before'
                    )?_c('el-date-picker',{attrs:{"size":"mini","placeholder":"Select Date","format":_vm.getDefaultDateFormat &&
                      _vm.getDefaultDateFormat.includes('Do') == false
                        ? _vm.getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_type == 'TIME_RANGE' &&
                      filter.operator == 'timeinclude'
                    )?_c('el-time-picker',{attrs:{"size":"mini","placeholder":"select time","picker-options":{
                      format: 'HH:mm:ss',
                    },"value-format":'HH:mm:ss'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'DATE_TIME' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'between' &&
                      filter.operator != 'dateinclude' &&
                      filter.operator != 'notdateinclude' &&
                      filter.operator != 'weekdays'
                    )?_c('el-date-picker',{attrs:{"placeholder":"Select Date","size":"mini","format":_vm.getDefaultDateFormat &&
                      _vm.getDefaultDateFormat.includes('Do') == false
                        ? _vm.getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'created_at'
                    )?_c('el-date-picker',{attrs:{"size":"mini","format":_vm.getDefaultDateFormat &&
                      _vm.getDefaultDateFormat.includes('Do') == false
                        ? _vm.getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.input_type === 'created_by' &&
                      filter.data_source === 'VALUE'
                    )?_c('el-select',{staticStyle:{"margin-left":"10px"},attrs:{"size":"mini","multiple":"","collapse-tags":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.users.data),function(user,index){return _c('el-option',{key:index,attrs:{"label":user.email,"value":user._id}})}),1):_vm._e(),(
                      filter.input_type === 'updated_by' &&
                      filter.data_source === 'VALUE'
                    )?_c('el-select',{staticStyle:{"margin-left":"10px"},attrs:{"size":"mini","multiple":"","collapse-tags":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.users.data),function(user,index){return _c('el-option',{key:index,attrs:{"label":user.email,"value":user._id}})}),1):(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'updated_at'
                    )?_c('el-date-picker',{attrs:{"size":"mini","format":_vm.getDefaultDateFormat &&
                      _vm.getDefaultDateFormat.includes('Do') == false
                        ? _vm.getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      (filter.data_type == 'DATE_TIME' ||
                        filter.data_type == 'DATE_RANGE') &&
                      filter.operator == 'dateinclude'
                    )?_c('el-date-picker',{attrs:{"size":"mini","format":_vm.getDefaultDateFormat &&
                      _vm.getDefaultDateFormat.includes('Do') == false
                        ? _vm.getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      (filter.data_type == 'DATE_TIME' ||
                        filter.data_type == 'DATE') &&
                      filter.operator == 'notdateinclude'
                    )?_c('el-date-picker',{attrs:{"size":"mini","format":_vm.getDefaultDateFormat &&
                      _vm.getDefaultDateFormat.includes('Do') == false
                        ? _vm.getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_source == 'REAL_TIME_PICKER' &&
                      filter.data_type == 'DATE_TIME' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'between' &&
                      filter.operator != 'weekdays'
                    )?_c('el-select',{attrs:{"placeholder":"Pick date range","size":"mini"},on:{"change":(changedValue) =>
                        _vm.setDatePickerDate(changedValue, filter)},model:{value:(filter.selected_date_picker),callback:function ($$v) {_vm.$set(filter, "selected_date_picker", $$v)},expression:"filter.selected_date_picker"}},[_c('el-option',{attrs:{"label":"Today","value":"today"}},[_vm._v("Today")]),_c('el-option',{attrs:{"label":"Yesterday","value":"yesterday"}},[_vm._v("Yesterday")])],1):_vm._e(),(
                      filter.data_type == 'DATE' &&
                      filter.operator == 'real_time'
                    )?_c('CustomDatePicker',{attrs:{"field":filter}}):_vm._e(),(
                      filter.data_type == 'DATE' &&
                      filter.operator == 'between'
                    )?_c('el-date-picker',{attrs:{"size":"mini","type":"daterange","align":"right","unlink-panels":"","range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_type == 'DATE' &&
                      filter.operator == 'notbetween'
                    )?_c('el-date-picker',{attrs:{"size":"mini","type":"daterange","align":"right","unlink-panels":"","range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_type == 'TIME' &&
                      filter.operator == 'between' &&
                      _vm.isFixed(filter) === false
                    )?_c('el-time-picker',{attrs:{"is-range":"","size":"mini","range-separator":"To","start-placeholder":"Start time","end-placeholder":"End time","picker-options":{
                      format: 'HH:mm:ss',
                    },"value-format":'HH:mm:ss'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_type == 'TIME' &&
                      filter.operator == 'notbetween' &&
                      _vm.isFixed(filter) === false
                    )?_c('el-time-picker',{attrs:{"is-range":"","size":"mini","range-separator":"To","start-placeholder":"Start Time","end-placeholder":"End Time","picker-options":{
                      format: 'HH:mm:ss',
                    },"value-format":'HH:mm:ss'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_type == 'TIME' &&
                      filter.operator == 'between' &&
                      _vm.isFixed(filter) === true
                    )?_c('el-time-picker',{attrs:{"is-range":"","size":"mini","range-separator":"To","start-placeholder":"Start Time","end-placeholder":"End Time","picker-options":{
                      format: 'HH:mm:ss',
                    },"value-format":'HH:mm'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_type == 'TIME' &&
                      filter.operator == 'notbetween' &&
                      _vm.isFixed(filter) === true
                    )?_c('el-time-picker',{attrs:{"is-range":"","size":"mini","range-separator":"To","start-placeholder":"Start time","end-placeholder":"End time","picker-options":{
                      format: 'HH:mm:ss',
                    },"value-format":'HH:mm'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'TIME' &&
                      _vm.isFixed(filter) === false &&
                      filter.operator != 'between'
                    )?_c('el-time-picker',{attrs:{"size":"mini","placeholder":"Select Time","picker-options":{
                      format: 'HH:mm:ss',
                    },"value-format":'HH:mm:ss'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(
                      filter.data_source == 'VALUE' &&
                      filter.data_type == 'TIME' &&
                      _vm.isFixed(filter) === true &&
                      filter.operator != 'between'
                    )?_c('el-time-select',{attrs:{"placeholder":"Select time","size":"mini","picker-options":{
                      start: '00:00',
                      step: '00:30',
                      end: '23:59',
                    }},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.data_source == 'GLOBAL_VARIABLE')?_c('el-select',{attrs:{"no-data-text":"No Global Varibles with Field","size":"mini"},on:{"change":(value) => _vm.addGlobalVaraibleData(value, filter)},model:{value:(filter.global_variable_id),callback:function ($$v) {_vm.$set(filter, "global_variable_id", $$v)},expression:"filter.global_variable_id"}},_vm._l((_vm.selectedGlobalVariablesList(
                        filter
                      )),function(gbVab,index){return _c('el-option',{key:index,attrs:{"label":`${gbVab.label} -> ${
                        gbVab.value ? gbVab.value : ''
                      }`,"value":gbVab._id}})}),1):_vm._e(),(
                      filter.data_source == 'NEW_LIST' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'weekdays'
                    )?_c('el-select',{attrs:{"placeholder":"Enter values","clearable":true,"allow-create":true,"default-first-option":true,"filterable":true,"multiple":true,"no-data-text":'',"size":"mini"},on:{"change":(data) => _vm.parseOptionsAsNumbers(data, filter)},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.data_source == 'ENTITY_DATA_LIST')?_c('el-select',{attrs:{"multiple":"","collapse-tags":"","size":"mini","filterable":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.entilistAsOptions(filter)),function(temp,i){return _c('el-option',{key:temp._id + '_' + index + '_' + i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}},[_vm._v(_vm._s(_vm.getLabel(temp, i)))])}),1):_vm._e(),(
                      (filter.data_source == '' ||
                        filter.data_source == undefined) &&
                      filter.operator != 'between' &&
                      filter.operator != 'notbetween' &&
                      filter.operator != 'dateinclude' &&
                      filter.operator != 'notdateinclude' &&
                      filter.operator != 'real_time' &&
                      filter.operator != 'weekdays' &&
                      filter.operator == 'Equals'
                    )?_c('el-input',{attrs:{"size":"mini","disabled":true},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.data_source == 'self_field')?_c('el-select',{attrs:{"collapse-tags":"","size":"mini"},model:{value:(filter.value_field),callback:function ($$v) {_vm.$set(filter, "value_field", $$v)},expression:"filter.value_field"}},_vm._l((_vm.getSelfTemplateFields(
                        filter
                      )),function(enityData,index){return _c('el-option',{key:index,attrs:{"label":enityData.label,"value":enityData.key}})}),1):_vm._e(),(filter.data_source == 'field_option')?_c('el-select',{attrs:{"collapse-tags":"","size":"mini","multiple":filter.operator == 'in' || filter.operator == 'nin'
                        ? true
                        : false},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},[(_vm.fieldType(filter, 'LIST'))?_vm._l((_vm.getCurrentFieldOptions(
                          filter
                        )),function(enityData,index){return _c('el-option',{key:index + '_' + enityData.value,attrs:{"label":enityData.name,"value":enityData.value,"multiple":"","collapse-tags":""}})}):(_vm.fieldType(filter, 'WEEKDAYS'))?_vm._l((_vm.weekdays_options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.key,"label":option.name}})}):_vm._l((_vm.getCurrentFieldOptions(
                          filter
                        )),function(enityData,index){return _c('el-option',{key:index,attrs:{"label":enityData,"value":enityData,"multiple":"","collapse-tags":""}})})],2):_vm._e()],1)]):_vm._e(),(
                  (((filter.data_source == 'GLOBAL_VARIABLE' &&
                    filter.global_variable_id) ||
                    filter.data_source == 'self_field') &&
                    _vm.checkIsSelect(filter)) ||
                  (filter.data_type == 'DATE' &&
                    filter.operator === 'real_time')
                )?_c('td',[(
                    filter.operator === 'real_time' &&
                    _vm.everyYearFilters.indexOf(filter.selected_date_picker) ===
                      -1
                  )?_c('el-checkbox',{model:{value:(filter.every_year),callback:function ($$v) {_vm.$set(filter, "every_year", $$v)},expression:"filter.every_year"}},[_vm._v("Show every year ?")]):_vm._e(),(filter.operator !== 'real_time')?_c('el-select',{attrs:{"size":"mini"},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}},_vm._l((_vm.getCurrentGlobalVaribleOptions(
                      filter
                    )),function(op,index){return _c('el-option',{key:index,attrs:{"multiple":filter.operator == 'in' || filter.operator == 'nin'
                        ? true
                        : false,"value":op,"label":op}})}),1):_vm._e()],1):_vm._e(),(filter.data_table_key)?_c('td',[_c('el-tooltip',{attrs:{"effect":"dark","content":"Allow rows must satisfy this rule","placement":"top-start"}},[_c('el-checkbox',{model:{value:(filter.all_rows),callback:function ($$v) {_vm.$set(filter, "all_rows", $$v)},expression:"filter.all_rows"}})],1)],1):_vm._e(),_c('td',[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.removeFilterFromList(index)}}},[_vm._v("Remove")])],1)])])])}),0):_vm._e()]),_c('span',{staticClass:"warning"},[_vm._v("Note: The quick filters of the first "),_c('em',[_vm._v("Relation-with #Entity")]),_vm._v(" Filter will be displayed alongside the current filter's quick filters.")]),_c('el-divider'),(
        (_vm.entityFiltersData.isSaveFilter || _vm.entityFiltersData._id) &&
        !this.$route.query.routeType
      )?_c('div',{staticClass:"mt-1 d-flex",staticStyle:{"gap":"20px"}},[(
          (_vm.entityFiltersData.isSaveFilter || _vm.entityFiltersData._id) &&
          !this.$route.query.routeType
        )?_c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" Filter name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('el-input',{attrs:{"placeholder":"Enter filter name","size":"mini"},model:{value:(_vm.entityFiltersData.filter_name),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "filter_name", $$v)},expression:"entityFiltersData.filter_name"}})],1):_vm._e(),_c('div',[(!this.$route.query.routeType)?_c('span',{staticClass:"select-fields"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" Select fields to use as quick filters ")]),_c('el-select',{attrs:{"multiple":"","placeholder":"Select fields","size":"mini","collapse-tags":"","clearable":""},model:{value:(_vm.entityFiltersData.quick_filters),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "quick_filters", $$v)},expression:"entityFiltersData.quick_filters"}},_vm._l((_vm.getQuickFilterFilters),function(field,index){return _c('el-option',{key:index + '_' + field.key,attrs:{"value":field.key,"label":`${field.template_name} - ${field.label}`,"disabled":_vm.checkFilterExistedOrNot(field)}},[_vm._v(_vm._s(`${field.template_name} - ${field.label}`))])}),1)],1):_vm._e()]),_c('div',{staticClass:"Filters-GroupBy"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Group by")]),_c('el-select',{attrs:{"placeholder":"Group by","size":"mini","clearable":""},on:{"change":_vm.addDefaultColumn},model:{value:(_vm.entityFiltersData.tableFieldGroupBy),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "tableFieldGroupBy", $$v)},expression:"entityFiltersData.tableFieldGroupBy"}},_vm._l((_vm.getQuickFilterFilters.filter(
              (e) =>
                e.inputType != 'DATE' &&
                _vm.entityFiltersData.tableFieldSubGroupBy != e.key
            )),function(field,index){return _c('el-option',{key:index + '_' + field.key,attrs:{"value":field.key,"label":`${field.template_name} - ${field.label}`}},[_vm._v(_vm._s(`${field.template_name} - ${field.label}`))])}),1)],1),_c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Sub group by")]),_c('el-select',{attrs:{"placeholder":"Sub group by","size":"mini","clearable":""},on:{"change":_vm.getSelectedFieldOptions},model:{value:(_vm.entityFiltersData.tableFieldSubGroupBy),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "tableFieldSubGroupBy", $$v)},expression:"entityFiltersData.tableFieldSubGroupBy"}},_vm._l((_vm.getQuickFilterFilters.filter(
              (e) =>
                e.inputType != 'DATE' &&
                _vm.entityFiltersData.tableFieldGroupBy != e.key
            )),function(field,index){return _c('el-option',{key:index + '_' + field.key,attrs:{"value":field.key,"label":`${field.template_name} - ${field.label}`}},[_vm._v(_vm._s(`${field.template_name} - ${field.label}`))])}),1)],1),(_vm.entityFiltersData.tableFieldSubGroupBy)?_c('div',[_c('span',{staticClass:"opt"},[_c('p',{staticClass:"m-01"},[_vm._v("Options")]),_c('div',{staticClass:"select-wrapper"},[_c('el-select',{attrs:{"placeholder":"Select options to group","multiple":"","clearable":"","collapse-tags":"","size":"mini"},model:{value:(_vm.entityFiltersData.tableFieldGroupBySelectedOptions),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "tableFieldGroupBySelectedOptions", $$v)},expression:"entityFiltersData.tableFieldGroupBySelectedOptions"}},[(
                  _vm.checkFieldType(
                    _vm.entityFiltersData.tableFieldSubGroupBy,
                    'ENTITY'
                  )
                )?_vm._l((_vm.groupByFieldEntityValues),function(data,index){return _c('el-option',{key:index + 'entity' + data._id,attrs:{"value":data._id,"label":_vm.getLabelForEntityData(data, index)}},[_c('span',[_vm._v(_vm._s(_vm.getLabelForEntityData(data, index)))])])}):_vm._l((_vm.pieChartFieldGroupByOptions),function(option,index){return _c('el-option',{key:index,attrs:{"value":option,"label":option}},[_c('span',[_vm._v(_vm._s(option))])])})],2)],1)])]):_vm._e()]):_vm._e(),(
        _vm.entityFiltersData.permissions &&
        (_vm.entityFiltersData.isSaveFilter || _vm.entityFiltersData._id) &&
        !this.$route.query.routeType
      )?_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"mt-1 d-flex",staticStyle:{"gap":"20px"}},[_c('div',{staticClass:"flex-column"},[_c('span',[_vm._v("Templates access")]),_c('el-select',{attrs:{"multiple":"","collapse-tags":"","size":"mini"},on:{"change":_vm.checkStandardTemplate},model:{value:(_vm.entityFiltersData.permissions.templates),callback:function ($$v) {_vm.$set(_vm.entityFiltersData.permissions, "templates", $$v)},expression:"entityFiltersData.permissions.templates"}},_vm._l((_vm.getCurrentTemplates),function(op,i){return _c('el-option',{key:i + op._id,attrs:{"value":op._id,"label":op.name}},[_vm._v(_vm._s(op.name))])}),1)],1),_c('div',{staticClass:"flex-column"},[_c('span',[_vm._v("Display Type")]),_c('el-select',{attrs:{"placeholder":"Select a view"},model:{value:(_vm.entityFiltersData.displayType),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "displayType", $$v)},expression:"entityFiltersData.displayType"}},_vm._l((_vm.display_views),function(view){return _c('el-option',{key:view.key,attrs:{"label":view.label,"value":view.key}},[_vm._v(_vm._s(view.label))])}),1)],1),_c('div',{staticClass:"Filters-Container"},[_c('div',{staticClass:"Filter-Items"},[_c('div',{staticClass:"Filter-Item"},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_vm._v("Sort Field")]),_c('el-select',{attrs:{"placeholder":"Select Entity Field","size":"mini","filterable":"","clearable":""},model:{value:(_vm.entityFiltersData.sortBy),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "sortBy", $$v)},expression:"entityFiltersData.sortBy"}},_vm._l((_vm.selectedEntityFields.filter(
                    (e) => _vm.contentFields.indexOf(e.inputType) === -1
                  )),function(field,fieldIndex){return _c('el-option',{key:fieldIndex,attrs:{"value":_vm.matchingValue(field),"label":`${field.template_name} - ${field.label}`}},[_vm._v(" "+_vm._s(`${field.template_name} - ${field.label}`)+" ")])}),1)],1),_c('div',{staticClass:"Filter-Item"},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_vm._v("Sort Order")]),_c('el-select',{attrs:{"placeholder":"Select Order","size":"mini","filterable":"","clearable":""},model:{value:(_vm.entityFiltersData.sortOrder),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "sortOrder", $$v)},expression:"entityFiltersData.sortOrder"}},_vm._l((_vm.sortOrderOptions),function(option,optIndex){return _c('el-option',{key:optIndex,attrs:{"value":option.value,"label":option.label}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1)])]),(
            _vm.entityFiltersData.displayType &&
            _vm.entityFiltersData.displayType == 'HTML_CONTENT'
          )?_c('div',{staticClass:"flex-column"},[_c('span',[_vm._v("Select Email Template")]),_c('el-select',{attrs:{"placeholder":"Select a view"},model:{value:(_vm.entityFiltersData.email_template_id),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "email_template_id", $$v)},expression:"entityFiltersData.email_template_id"}},_vm._l((_vm.emailTemplates),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.name,"value":item._id}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),(_vm.entityFiltersData.displayType == 'TABLE')?_c('div',{staticClass:"flex-column"},[_c('span',[_vm._v(" Data limit")]),_c('el-select',{attrs:{"placeholder":"Select a limit"},model:{value:(_vm.entityFiltersData.data_limit),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "data_limit", $$v)},expression:"entityFiltersData.data_limit"}},_vm._l(([5, 10, 20, 50, 100]),function(limit){return _c('el-option',{key:limit + '_limit',attrs:{"label":limit,"value":limit}},[_vm._v(_vm._s(limit))])}),1)],1):_vm._e()]),_c('br'),_c('div',{staticClass:"d-flex mt-1"},[_c('p',{staticClass:"mr-2 textDisplay"},[_vm._v("Permissions")]),_c('el-checkbox-group',{on:{"change":_vm.updatePermissions},model:{value:(_vm.entityFiltersData.permissions.access_set),callback:function ($$v) {_vm.$set(_vm.entityFiltersData.permissions, "access_set", $$v)},expression:"entityFiltersData.permissions.access_set"}},_vm._l(([
              'VIEW',
              'ADD',
              'UPDATE',
              'DUPLICATE',
              'DELETE',
              'IMPORT',
              'EXPORT',
              'LAYOUT OPTIONS',
              'SETTINGS',
              'ROW DATA EDIT',
            ]),function(access,i){return _c('el-checkbox',{key:i + access,attrs:{"label":access,"disabled":_vm.checkPermissions(access)}},[_vm._v(_vm._s(access))])}),1)],1),_c('div',{staticClass:"d-flex mt-1"},[_c('p',{staticClass:"mr-2 textDisplay"},[_vm._v("Filter Permissions")]),_c('el-checkbox-group',{on:{"change":_vm.updatePermissions},model:{value:(_vm.entityFiltersData.permissions.filter_permissions),callback:function ($$v) {_vm.$set(_vm.entityFiltersData.permissions, "filter_permissions", $$v)},expression:"entityFiltersData.permissions.filter_permissions"}},_vm._l((['CREATE FILTER', 'SAVE FILTER']),function(access,i){return _c('el-checkbox',{key:i + access,attrs:{"label":access,"disabled":access === 'SAVE FILTER' &&
              !_vm.entityFiltersData.permissions.filter_permissions.includes(
                'CREATE FILTER'
              )}},[_vm._v(_vm._s(access))])}),1)],1)]):_vm._e(),_c('div',[(_vm.entityUserFiltersData.isSaveFilter || _vm.entityUserFiltersData._id)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" Filter name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('el-input',{attrs:{"placeholder":"Enter filter name","size":"mini"},model:{value:(_vm.entityUserFiltersData.filter_name),callback:function ($$v) {_vm.$set(_vm.entityUserFiltersData, "filter_name", $$v)},expression:"entityUserFiltersData.filter_name"}})],1):_vm._e()]),(
        !this.$route.query.routeType &&
        _vm.entityFiltersData &&
        !_vm.entityFiltersData._id &&
        ((_vm.entityFiltersData.filters && _vm.entityFiltersData.filters.length) ||
          (_vm.entityFiltersData.quick_filters &&
            _vm.entityFiltersData.quick_filters.length))
      )?_c('el-checkbox',{staticClass:"mt-1",model:{value:(_vm.entityFiltersData.isSaveFilter),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "isSaveFilter", $$v)},expression:"entityFiltersData.isSaveFilter"}},[_vm._v("Save this filter for future use or custom menu configuration?")]):_vm._e(),(_vm.showSaveFilterCheckbox)?_c('el-checkbox',{staticClass:"mt-1",model:{value:(_vm.entityUserFiltersData.isSaveFilter),callback:function ($$v) {_vm.$set(_vm.entityUserFiltersData, "isSaveFilter", $$v)},expression:"entityUserFiltersData.isSaveFilter"}},[_vm._v("Save this filter for future use ?")]):_vm._e(),(_vm.entityFiltersData._id && !this.$route.query.routeType)?_c('el-checkbox',{staticClass:"mt-1",model:{value:(_vm.entityFiltersData.updateFilter),callback:function ($$v) {_vm.$set(_vm.entityFiltersData, "updateFilter", $$v)},expression:"entityFiltersData.updateFilter"}},[_vm._v("Update this filter?")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }